import { Component, inject } from '@angular/core';
import { ChangePasswordComponent } from '../settings/change-password/change-password.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LeaseService } from '@app/services/lease.service';
import { LoaderService } from '@app/shared/loader.service';
import { UserService } from '@app/services/user.service';
import { SharedService } from '@app/services/shared.service';

@Component({
  selector: 'app-personal-setting',
  templateUrl: './personal-setting.component.html',
  styleUrls: ['./personal-setting.component.scss']
})
export class PersonalSettingComponent {

  personalinfo: any;
  porfolioName: any;
  filterProperty: any;
  selectedPortfolio: any;
  selectedProperties: any[] = [];
  userOperator: any;
  userId: string | null;
profile:any=''
  private sharedService = inject(SharedService);
  constructor(private userService: UserService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef, private loader: LoaderService) { this.loader.show() }

  private leasingservice = inject(LeaseService);


  ngOnInit() {
    this.userService.userInfo.subscribe((prof: any) => {
      this.profile=prof.picture;
      this.userId = prof?.userid;
      if (this.userId) {
        this.leasingservice.getusers(this.userId).subscribe((res: any) => {
          if (res) {
            this.personalinfo = res[0];
            this.porfolioName = this.personalinfo[0].Portfolios.map((portfolio: any) => portfolio.Name);
            this.selectedPortfolio = this.porfolioName[0];
            this.filterProperty = this.personalinfo[0].Portfolios.filter((item: { Name: string; }) => item.Name === this.selectedPortfolio)
            this.selectedProperties = this.filterProperty[0].Properties.map((property: any) => property.Name);
            this.userOperator = this.filterProperty[0].Properties[0].OperatorName;
          }
        })
      }
    })

  }



  getPortfolios(params: any): string {
    return params?.data?.Portfolios?.map((portfolio: any) => portfolio?.Name)?.join(", ");
  }
  getProperty(params: any): string {
    if (params.data.Role == "User" || params.data.Role == "View Only User") {
      const properties = params?.data?.Portfolios?.flatMap((portfolio: any) => portfolio.Properties.map((property: any) => property.Name));
      const prop = Array.from(
        new Map(properties?.map((item: any) => [item, item])).values()
      );
      return prop.join(', ');
    }
    else {
      return "";
    }
  }

  onPortfolioChange() {
    if (this.selectedPortfolio) {
      let selectedData = this.personalinfo[0].Portfolios.filter((item: { Name: string; }) => item.Name === this.selectedPortfolio);
      this.selectedProperties = selectedData[0].Properties.map((property: any) => property.Name);
    } else {
      this.selectedProperties = [];
    }
  }

  changePassword() {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Change Password',
        userDetails: this.personalinfo
      },
      class: 'modal-lg',
    };
    this.bsModalRef = this.modalService.show(
      ChangePasswordComponent,
      initialState
    );
    this.sharedService.modalRef = this.bsModalRef;
  }


}
