import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from './services/shared.service';
import { LoaderService } from './shared/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit,AfterViewInit {
  public router = inject(Router);
  chckabc: any;
  visible:any=true;
  constructor(private sharedService: SharedService,private loaderService:LoaderService) { }
  subscription:Subscription
  ngOnInit() {
    this.sharedService.sideBar.subscribe((res: any) => {
      this.chckabc = res;
    });
   
  }
ngAfterViewInit(){
  this.subscription?.unsubscribe();
 this.subscription= this.loaderService.isloading.subscribe((state: any) => {
     if(state==true|| state==false)
     this.visible = state;
   });
}
  ngOnDestroy() {  }

}
