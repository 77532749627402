import { Component, OnInit, inject } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Router } from "@angular/router";
import { SharedService } from "@app/services/shared.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	selectedValue = "1";
	isConventionalSelected: boolean = true;
	isConventionalMarketSelected = true;
	private router = inject(Router);
	public auth = inject(AuthService);
	conventionalType: any;
	conventionalMarket: any;
	constructor(
		private sharedService: SharedService,
	) {

	}
	onDropdownValueChanged(event: any) {
		this.sharedService.conventionalData.next(event);
		this.isConventionalSelected = event
		this.sharedService.headerCheckBox.next(event);
	}
	onDropdownChange(event: any) {
		this.sharedService.conventionalDataMarket.next(event);
	}
	onChange(event: any) {
		let stateData = this.sharedService.getMarketOverviewDropdownStateValue();
		// stateData.seleteType = event;
		stateData.marketOrleasing = event;
		this.sharedService.updateMarketOverviewDropdownState(stateData);
		if (event === "1") {
			if (this.isConventionalSelected) {
				this.router.navigate(["leasing"]);
			}
			else {
				this.router.navigate(["leasing/conventionaldashboard"]);
			}
			// this.router.navigate(["leasing"]);

		} else if (event === "2") {
			this.conventionalType = false;
			this.conventionalMarket = true
			this.router.navigate(["market-overview"]);

		}
	}

	ngOnInit(): void {
		if (this.router.url.includes("market")) {
			this.selectedValue = "2";
		}
		this.sharedService.headerCheckBox.next(this.isConventionalSelected);
		this.sharedService.headerCheckBox.subscribe((res: any) => {
			this.isConventionalSelected = res;
		});
		this.sharedService.conventionalDataToggle.subscribe((res: any) => {
			this.conventionalType = res;
		});
		this.sharedService.conventionalDataToggleMarket.subscribe((res: any) => {
			this.conventionalMarket = res;
		});
	}

	logout() {
		this.auth.logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}

	redirect() {
		if (this.selectedValue === "1") {
			this.router.navigate(["leasing"]);
		} else {
			this.router.navigate(["market-overview"]);
		}
	}
}
