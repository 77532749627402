import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LeaseService } from '@app/services/lease.service';
import { AuthService } from '@auth0/auth0-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  changePassword:FormGroup;
  passwordsMatching = false;
  isConfirmPasswordDirty = false;
  confirmPasswordClass = 'form-control';
  public auth = inject(AuthService);
  newPassword = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
    Validators.pattern(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
    ),UsernameValidator.cannotContainSpace
  ]);
  currentPassword= new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
  ],);
  confirmPassword = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
   ],);
  title:any;
  userDetails:any;
  constructor(private fb:FormBuilder,private leasingservice:LeaseService,public bsModalRef: BsModalRef,
){

  }
  companyList:any;
  ngOnInit(): void {
    this.changePassword = this.fb.group({
      userId:['',Validators.required],
      name: [{value:'',disabled: true}],
      lastname: [{value:'',disabled: true}],
      email: [{value:'',disabled: true}],
      phone: [{value:'',disabled: true} ],
      currentPassword:this.currentPassword,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword
    }, {
      validators: [this.PasswordMatchValidator('currentPassword','newPassword'),this.ConfirmedValidator('newPassword', 'confirmPassword')],
    });
    this.setFormValue()
  }
  setFormValue(){
    this.changePassword.patchValue({
      userId:this.userDetails[0].UserId,
      name: this.userDetails[0].FirstName,
      lastname: this.userDetails[0].LastName,
      email: this.userDetails[0].Email,
      phone: this.userDetails[0]?.Phone,
    })
  }
  onSubmit(){
  if(this.changePassword.invalid){
    return;
  }else{
    this.resetPassword(this.changePassword.value);
  }
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  isEdit:false;
  redirectMethod(): void {
  }
  resetPassword(value:any){
    let payload={
        "id":value.userId,
        "newPassword": value.newPassword,
        "oldPassword": value.currentPassword
    }
    this.leasingservice.updatePassowrd(payload).subscribe((res:any)=>{
      if(res?.status_code){
        Swal.fire('Entered old password is incorrect, Kindly enter the correct old password');
      }else{
        Swal.fire("User password saved successfully");
          this.auth.logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });      
      }
    })
  }
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }       
  }
 PasswordMatchValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
  const control = formGroup.controls[controlName];
  const matchingControl = formGroup.controls[matchingControlName];
  if (
    matchingControl.errors &&
    !matchingControl.errors.confirmedValidator
  ) {
    return;
  }
  if (control.value == matchingControl.value) {
    matchingControl.setErrors({ confirmedValidator: true });
  }else{
    matchingControl.setErrors(null);
  }
  }
  }
  password:any='password';
  npassword:any='password';
  cpassword:any='password';
  onClick(type:number) {
    switch(type) { 
      case 1: { 
        if (this.password === 'password') {
          this.password = 'text';
        } else {
          this.password = 'password';
        }         break; 
      } 
      case 2: { 
        if (this.npassword === 'password') {
          this.npassword = 'text';
        } else {
          this.npassword = 'password';
        }         break;          break; 
      } 
      case 3: { 
        if (this.cpassword === 'password') {
          this.cpassword = 'text';
        } else {
          this.cpassword = 'password';
        }         break;         break; 
     }
      default: { 
         break; 
      } 
   } 
    
  }
}
import {  ValidationErrors } from '@angular/forms';  
    
export class UsernameValidator {  
    static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {  
        if((control.value as string)?.indexOf(' ') >= 0){  
            return {cannotContainSpace: true}  
        }  
    
        return null;  
    }  
}  