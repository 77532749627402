import { Component, OnInit, inject } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Event, NavigationEnd, Router } from "@angular/router";
import { SharedService } from "@app/services/shared.service";
import { LeasingApiService } from "@app/services/leasing-api.service";
import { UserService } from "@app/services/user.service";
import { forkJoin, Subscription } from "rxjs";
import { MarketService } from "@app/services/market.service";
import { LoaderService } from "@app/shared/loader.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	selectedValue = "1";
	headerDropdownValue = 1;
	isConventionalMarketSelected = true;
	private router = inject(Router);
	public auth = inject(AuthService);
	public userService = inject(UserService);
	conventionalType: boolean = false;
	conventionalMarket: any;
	operatorList: any = [];
	selectedoperator: any;
	operatorselected: any;
	propertyList = [];
	AllPropertyList = [];
	weekList = [];
	portfolioList = [];
	conventionalPropertyList = [];
	conventionalWeekList = [];
	newLeasCount: any = [];
	isShowOnly = true;
	constructor(
		public sharedService: SharedService,
		private leasingApiService: LeasingApiService
	) {
		if (this.router.url.includes('conventional')) {
			this.headerDropdownValue = 2
		} else {
			this.headerDropdownValue = 1
		}
		this.sharedService.WebType = this.headerDropdownValue;
		this.sharedService.conventionalType = this.headerDropdownValue;
		this.sharedService.isLoader = true;
		this.getListData();
	}
	getListData() {
		let obsverables = [
			this.leasingApiService.getLeaseTermList(),
			this.leasingApiService.getPortfolioList(),
			this.leasingApiService.getPropertyList(),
			this.leasingApiService.getConventionalPropertyList(),
			this.leasingApiService.getConvetialUserLeaseTerm(),
			this.marketService.getAllCompset()
		];
		forkJoin(obsverables).subscribe((res: any) => {
			if (res) {
				this.weekList = res[0];
				this.portfolioList = res[1];
				this.compsetList = res[5];
				this.conventionalWeekList = res[4] ? res[4] : [];
				this.propertyList = res[2]?.sort((a: any, b: any) =>
					a.PropertyName.localeCompare(b.PropertyName),
				);
				this.operatorList = res[2].filter(
					(property: any, index: any, self: any) =>
						index ===
						self.findIndex(
							(p: any) => p.Operator_Id === property.Operator_Id,
						),
				)
					.sort((a: any, b: any) =>
						a.Operator_Name.localeCompare(b.Operator_Name),
					);
				this.selectedoperator = this.operatorList[0];
				this.operatorselected = this.operatorList[0];
				this.sharedService.marketOperatorID = this.operatorList[0].Operator_Id;
				this.sharedService.leasingPropertyList = this.propertyList;
				if (this.selectedoperator.Operator_Id == "18371453-902e-11ee-b225-000d3a53b49c") {
					// if (this.router.url.includes('market-overview')) {
					// 	this.conventionalType = false;
					// } else {
					this.conventionalType = true;

					// }
				}
				this.AllPropertyList = res[3];
				this.sharedService.selectedMarketId = this.selectedoperator?.Market_Id;
				this.conventionalPropertyList = res[3]?.filter((item: any) => item.Operator_Id == this.selectedoperator.Operator_Id);
				this.sharedService.conventionalPropertyList = this.conventionalPropertyList ? this.conventionalPropertyList : [];
				let object = {
					operatorList: this.operatorList,
					propertyList: this.propertyList,
					selectedoperator: this.selectedoperator,
					weekList: this.weekList,
					portfolioList: this.portfolioList,
					conventionalPropertyList: this.conventionalPropertyList,
					conventionalWeekList: this.conventionalWeekList,
					compsetList: this.compsetList
				}
				this.sharedService.conventionalType = this.headerDropdownValue;
				this.sharedService.WebType = this.headerDropdownValue;
				this.userService.selectedOperator = object;
			}
		});
	}
	subscription2: Subscription;
	marketService = inject(MarketService);
	loader = inject(LoaderService);

	marketLeaseTerm: any;
	datasubscription: Subscription;
	marketGlobalWeeks: any
	compsetList: any;

	onOperatorChange(event: any,) {
		console.log(event);
		this.operatorselected = event;
		this.sharedService.isLoader = true;
		if (event?.Operator_Id) {
			if (event.Operator_Id == "18371453-902e-11ee-b225-000d3a53b49c") {
				if (this.router.url.includes('conventional') && this.headerDropdownValue == 2) {
					this.router.navigate(['/leasing']);
				}
				this.conventionalType = true;
			} else {
				this.headerDropdownValue = 1;
				this.router.url.includes('conventional') ? this.router.navigate(['/leasing']) : '';
				this.conventionalType = false;

			}
			this.conventionalPropertyList = this.AllPropertyList?.filter((item: any) => item.Operator_Id == event?.Operator_Id)
			this.sharedService.selectedMarketId = event?.Market_Id;

			let object = {
				operatorList: this.operatorList,
				propertyList: this.propertyList,
				selectedoperator: event,
				weekList: this.weekList,
				portfolioList: this.portfolioList,
				conventionalPropertyList: this.conventionalPropertyList,
				conventionalWeekList: this.conventionalWeekList,
				compsetList: this.compsetList
			}
			this.sharedService.conventionalType = this.headerDropdownValue;
			this.sharedService.WebType = this.headerDropdownValue;
			this.userService.selectedOperator = object;
			this.sharedService.marketOperatorID = event.Operator_Id;
		}
	}

	onChangeConventional(event: any) {
		this.headerDropdownValue = event;
		if (this.headerDropdownValue && this.router.url.includes('conventional')) {
			this.router.navigate(['/leasing']);
		}
		else if (this.headerDropdownValue == 2) {
			this.router.navigate(['/leasing/conventionaldashboard']);
		}
		this.sharedService.conventionalType = this.headerDropdownValue;
		this.sharedService.WebType = this.headerDropdownValue;
	}

	ngOnInit(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (event.url.includes('market')) {
					this.isShowOnly = false;
				} else {
					this.isShowOnly = true;
				}
				// if (event.url.includes('market')) {
				// 	this.conventionalType = false;
				// // } else if (event.url.includes('leasing') && this.selectedoperator?.Operator_Id == "18371453-902e-11ee-b225-000d3a53b49c") {
				// } else
				if (this.selectedoperator?.Operator_Id == "18371453-902e-11ee-b225-000d3a53b49c") {

					this.conventionalType = true;
				} else {
					this.conventionalType = false;
				}
				if (this.router.url == "/leasing") {
					this.headerDropdownValue = 1;
					this.sharedService.conventionalType = this.headerDropdownValue;
				}
			}
		});

		// if (this.router.url.includes("market")) {
		// 	this.selectedValue = "2";
		// 	this.conventionalType = false;
		// }
		if (this.router.url.includes("market")) {
			this.isShowOnly = false;
		} else {
			this.isShowOnly = true;
		}
		if (this.router.url.includes("conventional")) {
			this.headerDropdownValue = 2;
		}
		this.sharedService.conventionalType = this.headerDropdownValue;
		this.sharedService.getLeases().subscribe(res => {
			this.newLeasCount = res;
			if (this.newLeasCount?.length > 0) {
			}
		});
		this.sharedService.notificationState.subscribe((res: Boolean) => {
			this.isActiveNotification = res;
			this.isShow = res;
		})
	}
	isActiveNotification: any;
	isShowNotification = false;
	isShow: any = false;
	notificationState() {
		this.isActiveNotification = false;
		this.sharedService.notificationState.next(!this.isShow)
	}
	logout() {
		this.auth.logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}

	redirect() {
		if (this.headerDropdownValue === 1) {
			this.router.navigate(["leasing"]);
		} else {
			this.router.navigate(["leasing/conventionaldashboard"]);
		}
	}

}
