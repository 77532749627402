import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx-js-style';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
	exportToExcel(rowdata: any,columnHeader:string[],cols:[],sheetNames:string): void {
		const modifyJson = this.modifyHeaders(rowdata, columnHeader)
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifyJson);
		ws['!cols'] = cols

		const headerStyle = {
			font: { bold: true, color: { rgb: 'FFFFFF' } },
			fill: { fgColor: { rgb: '4F81BD' } },
			alignment: { horizontal: 'center', vertical: 'center' }
		};
		for (var i in ws) {
			if (typeof ws[i] != 'object') continue;
			let cell = XLSX.utils.decode_cell(i);
			ws[i].s = {
				alignment: {
					vertical: 'center',
					horizontal: 'center',
					wrapText: '1',
				},
			};
		}
		let v: any = ws['!ref']
		const range = XLSX.utils.decode_range(v);
		for (let col = range.s.c; col <= range.e.c; col++) {
			const cell = ws[XLSX.utils.encode_cell({ r: 0, c: col })];
			if (cell) {
				cell.s = headerStyle;
			}
		}
    const workbook={ Sheets: { [sheetNames]: ws }, SheetNames: [sheetNames] }
		const wb: XLSX.WorkBook = workbook;
		XLSX.writeFile(wb, sheetNames+'.xlsx');
	}
  modifyHeaders(data: any, headerMapping: any) {
		const newData = data.map((item: any) => {
			const newItem: any = {};
			for (let key in item) {
				newItem[headerMapping[key] || key] = item[key];
			}
			return newItem;
		});
		return newData;
	};
  
}