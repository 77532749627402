import { Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaseService } from '@app/services/lease.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { concat, forkJoin } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-setting-modal',
  templateUrl: './setting-modal.component.html',
  styleUrls: ['./setting-modal.component.scss']
})
export class SettingModalComponent implements OnInit, OnChanges {
  userForm: FormGroup;
  role: any[]=[];
  portfolioList: any[]=[];
  clonePortfolioList: any[]=[];
  propertyList: any[]=[];
  title: any;
  isEdit = false;
  userInfo:any;
  closeBtn=false;
  constructor(private fb: FormBuilder, private leasingservice: LeaseService, public bsModalRef: BsModalRef,
  ) {
  }
  ngOnChanges() {

  }
  companyList: any;
  ngOnInit(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      company: ['',],
      title: ['',],
      portfolio: [''],
      property: [''],
      roletype: ['', Validators.required],
    });
    this.getData();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      "defaultOpen": false,
      "enableCheckAll": true,
      "itemsShowLimit": 999999,
      "allowSearchFilter": true,
      "limitSelection": null
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'portfolio_id',
      textField: 'portfolio_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      "defaultOpen": false,
      "enableCheckAll": false,
      "allowSearchFilter": true,

    };

  }
  getData() {
    let portfolioList = this.leasingservice.getportfolio();
    let roleList = this.leasingservice.getroles();
    let companyList = this.leasingservice.getUserCompanyList();
    forkJoin([portfolioList, roleList, companyList]).subscribe((res: any) => {
      this.role = res[1];
      this.clonePortfolioList = res[0];
      this.companyList = res[2];
      if(this.userInfo?.UserId){
        this.getUserByUserId(this.userInfo.UserId)}
    })
  }
  onSelectOperator(id: any) {
    this.userForm.controls.portfolio.setValue('');
    this.userForm.controls.roletype.setValue('');
    this.userForm.controls.property.setValue('');
    this.disabled1=true;
    this.userForm.controls.portfolio.clearValidators();
    this.userForm.controls.portfolio.updateValueAndValidity();
    this.userForm.controls.portfolio.disable();
    id ? this.userForm.controls.roletype.enable() : this.userForm.controls.roletype.disable();
    this.userForm.controls.property.disable();
    const portfolioData:any=this.clonePortfolioList;
    this.portfolioList=this.filterOperater(portfolioData)
   
  }
  filterOperater(portfolio: any) {
    const filteredItems = portfolio.map((item:any) => {
      return {
        ...item,
        Properties: item.Properties.filter((prop:any) => prop.Operator_Id == this.userForm.controls.company.value) // Filter items based on value
      };
    });
    return filteredItems.filter((innerArray:any)=>{return innerArray.Properties.length>0});
  }
  isShowProperty=true;
    onRoleChange(value: any) {
      const portfolioData:any=this.clonePortfolioList;
      this.portfolioList=this.filterOperater(portfolioData)
      this.disabled1=false;
      this.userForm.controls.portfolio.addValidators([Validators.required]);
      this.userForm.controls.portfolio.updateValueAndValidity();
      this.userForm.controls.portfolio.setValue('')
      this.userForm.controls.property.setValue('');
      this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 99999 });
      this.handleLimitSelection(false);
    if (value.role_id == 4 || value.role_id == 5) {
      this.disabled = false;
      this.userForm.controls.property.enable();
      this.userForm.controls.portfolio.enable();
      this.userForm.controls.property.addValidators([Validators.required]);
      this.userForm.controls.property.updateValueAndValidity();
      this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 1 });
      this.isShowProperty=false;
     
      this.handleLimitSelection(true);
      this.toogleShowAll(false);
      this.toogleShowFilter(true);
    } else if (value.role_id == 2 || value.role_id == 1) {
      this.disabled = true;
      this.isShowProperty=true;
      this.userForm.controls.portfolio.enable();
      this.handleLimitSelection(false);
      this.toogleShowAll(false);
      this.toogleShowFilter(false);
      if(this.portfolioList.length>0){
      const selectedPortfolio: any = this.portfolioList?.filter((item:any) => {return item.portfolio_id == -1});
      this.userForm.controls.portfolio.setValue(selectedPortfolio);
      this.propertyList=selectedPortfolio[0].Properties
      this.userForm.controls.property.setValue(selectedPortfolio[0].Properties);}

    } else {
      this.disabled = true;
      const portfolioData:any=this.clonePortfolioList;
      const filterData=this.filterOperater(portfolioData);
      this.portfolioList=filterData.filter((item:any)=>item.portfolio_id !== -1);
      this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 99999 });
      this.handleLimitSelection(false);
      this.toogleShowAll(true);
      this.toogleShowFilter(true);
      this.userForm.controls.portfolio.enable();
      this.userForm.controls.property.clearValidators();
      this.userForm.controls.property.updateValueAndValidity();
    }
  }
  filterProperties(selectedPortfolio:any){
    let selectedProp:any=[];
    selectedPortfolio.forEach((element:any) => {
      selectedProp= selectedProp.concat(element.Properties);
    });
  const prop=  Array.from(
      new Map(selectedProp.map((item:any) => [item.Id, item])).values()
    );
    return prop;
  }
  onSelectPortfolio(id: any) {
    this.userForm.controls.property.setValue('');
    const filteredArray = this.portfolioList.filter(item1 => 
      this.userForm.controls.portfolio.value.find((item2:any) => item2.portfolio_id === item1.portfolio_id)
    );
    this.propertyList= this.filterProperties(filteredArray)
    if(this.userForm.controls.roletype.value.role_id==4 || this.userForm.controls.roletype.value.role_id==5){
    this.userForm.controls.property.setValue('');
    }else{
      this.userForm.controls.property.setValue(this.propertyList);
    }
  }
  onSubmit() {
    if (this.userForm.invalid) {
      return;
    } else {
          if(this.userInfo?.UserId){
            this.updateUser(this.userForm.controls)
          }else{
            this.addNewUser(this.userForm.controls);}
          }
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  dropdownSettings: any
  disabled: any = false;
  dropdownSettings1: any
  disabled1: any = false;
  addNewUser(userInfo: any) {
     let selectedCompany=this.companyList.filter((item:any)=> item.Operator_Id==userInfo.company.value);
    let user = {
      "OperatorId":userInfo.company.value,
      "FirstName": userInfo.name.value,
      "LastName": userInfo.lastname.value,
      "Company": selectedCompany[0].Operator_Name,
      "Title": userInfo.title.value,
      "Portfolio": userInfo?.portfolio.value.map((item:any) => item.portfolio_id),
      "Property":userInfo?.property.value.map((item:any) => item.Id),
      "Email": userInfo.email.value,
      "Phone": userInfo.phone.value,
      "UserRole": userInfo.roletype.value.role_id,
      "isPublic": true
    }
    this.leasingservice.createusers(user).subscribe((res: any) => {
      if (res) {
        if (res.status_code == 409) {
          Swal.fire({
            title: "User Already Exists",
          });
        }
        else {
          Swal.fire({
            title: "User Created Successfully",
          });
          this.closeBtn=true
          this.closeModal();
        }
      }
    })
  }

  updateUser(userInfo:any){
   let selectedCompany=this.companyList.filter((item:any)=> item.Operator_Id==userInfo.company.value)
    let user = {
      "OperatorId":userInfo.company.value,
      "FirstName": userInfo.name.value,
      "LastName": userInfo.lastname.value,
      "Company": selectedCompany[0].Operator_Name,
      "Title": userInfo.title.value,
      "Portfolio": userInfo?.portfolio.value.map((item:any) => item.portfolio_id),
      "Property":userInfo?.property.value.map((item:any) => item.Id),
      "Email": userInfo.email.value,
      "Phone": userInfo.phone.value,
      "UserRole": userInfo.roletype.value.role_id,
      "isPublic": true
    }
    this.closeBtn=true
    this.closeModal();
  }
  toogleShowAll(value: boolean) {
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      enableCheckAll: value
    });
  }
  toogleShowFilter(value: boolean) {
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      allowSearchFilter: value
    });
  }
  handleLimitSelection(value: boolean) {
    if (value) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 99999 });

    }
  }
setUserFormData(userData:any){
 let selectedRole= this.role.filter(item=>item.role_name==userData.Role[0]);
 const selectedPortfolio=userData.Portfolios;
 const selectedProp=this.filterProperties(userData.Portfolios);
let portfolioMap= selectedPortfolio.map((item:any) => {
  const { Id,Name, ...rest } = item;
  return { portfolio_id: Id,portfolio_name: Name, ...rest };
});
this.userForm.patchValue({
  name: userData.FirstName,
  lastname:userData.LastName,
  email:userData.Email,
  phone: '',
  company:userData.Portfolios[0].Properties[0].OperatorId,
  title: userData.Title,
  roletype:selectedRole[0],
})
this.userForm.controls.portfolio.setValue(portfolioMap)
this.userForm.controls.property.setValue(selectedProp);
this.userForm.controls.email.disable()
}
getUserByUserId(userId:any){
  this.leasingservice.getusers(userId).subscribe((res: any) => {
    if (res) {
   this.setUserFormData(res[0]);
    }
  })
}
}
