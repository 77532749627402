<div class="helpSupprt">
    <div class="pageTitle">
        Help & Support
    </div>
    <div class="row">
        <div class="col-md-6 ">
            <div class="helpSupprtLeft">
                <div class="helpColmn">
                    <div class="helpText">Help</div>
                    <div>
                        <form class="form-inline my-2 my-lg-0">
                            <input class="form-control mr-sm-2" class="istyle" type="search" placeholder="Search All Topics" aria-label="Search">                        
                        </form>
                    </div>                 
                </div>
            </div>
            
        </div>
        <div class="col-md-6">
            <div class="helpSupprtRight">
                <div class="helpColmn">
                    <div class="helpText">SUPPORT TICKET</div>                              
                </div>
            </div>            
            <iframe src="
                https://forms.monday.com/forms/embed/ad19ecffae3729a380cbdbd1d4da7302?r=use1
                " width="100%" style="border: 0; box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);"></iframe>
        </div>
    </div>
</div>