
<div class="my-profile-section">
    <div class="heading1">MY PROFILE</div>
    <div class="heading2">Personal Settings </div>
    <div class="profile-setting-section">
        <div class="profile-text">Profile Picture</div>
        <div class="profile-detail">
            <div class="d-flex align-items-center">
                <div class="profile-img"><img [src]="profile?profile:''"></div>
                <div class="profile-name"><span class="name">{{personalinfo.FirstName}} {{personalinfo.LastName}}</span><br><span class="title">{{personalinfo.Title}}</span>
                </div>
            </div>
            <div class="change-pic-btn"><span>Change Picture</span></div>
        </div>
        <div class="contact-details">
            <div class="row">
                <div class="col-md-12">Contact Details</div>
                <div class="col-md-6">
                    <span class="text">Email ID</span><br>
                    <span class="text-data">{{personalinfo.Email}}</span>
                </div>
                <div class="col-md-6">
                    <span class="text">Phone</span><br>
                    <span class="text-data">{{personalinfo?.Phone}}</span>
                </div>
            </div>
        </div>
        <div class="profile-details">
            <div class="row">
                <div class="col-md-12">Select option</div>
                <div class="col-md-6">
                    <span class="text">Company Name</span><br>
                    <span class="text-data">{{personalinfo.Company}}</span>
                </div>
                <div class="col-md-6">
                    <span class="text">Role</span><br>
                    <span class="text-data">{{personalinfo?.Role[0]}}</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-6">
                    <span class="text">Portfolio</span><br>
                    <span class="text-data">{{selectedPortfolio}}</span>
                </div>
                <div class="col-md-6">
                    <span class="text">Properties</span><br>
                    <span class="text-data">{{selectedProperties}}</span>
                </div>
            </div>
        </div>
    </div>
</div>