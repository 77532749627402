import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LeaseTerm, ValidationStatus } from '@app/utils/types';
import { environment } from 'src/environments/environment';

const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
@Injectable({
  providedIn: 'root',
})
export class LeaseService {
  constructor(private http: HttpClient) { }

  getLeasesByUser() {
    return this.http.get(`${environment.apiEndpoint}/lease/leasetermbyuse`, {
      headers: headers,
    });
  }

  getEntitledLease(leaseTerm: string, WeekEndingDate: string, operatorId: string, PropertyId?: string, PortfolioId?: string) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/userentitledleases`,
      {
        Operator_Id: operatorId,
        Portfolio_Id: PortfolioId,
        Property_Id: PropertyId,
        Lease_Term: leaseTerm,
        WeekEndingDate: WeekEndingDate,
      },
      { headers: headers }
    );
  }

  getPricingDetail(Lease_Id: string = '', LeaseTerm: string) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/pricingdetails`,
      {
        Lease_Id: Lease_Id,
        LeaseTerm: LeaseTerm
      },
      { headers: headers }
    );
  }

  postPricingTier(body: any) {
    return this.http.put(
      `${environment.apiEndpoint}/lease/pricingtier`,
      body,
      { headers: headers }
    );
  }

  getPreleaseFlow(portfolioId?: string, propertyId?: string, leaseTerm?: LeaseTerm, Operator_Id?: string) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/weeklypreleasetrends`,
      {
        Portfolio_Id: portfolioId,
        Property_Id: propertyId,
        Lease_Term: leaseTerm,
        Operator_Id
      },
      { headers: headers }
    );
  }

  validateLease(leaseId: string, validationStatus: ValidationStatus) {
    return this.http.put(
      `${environment.apiEndpoint}/lease/validation`,
      {
        Lease_Id: [leaseId],
        Is_Validated: validationStatus,
      },
      { headers: headers }
    );
  }
  validateLeaseModal(leaseObj: any) {
    return this.http.put(
      `${environment.apiEndpoint}/lease/validation`,
      leaseObj,
      { headers: headers }
    );
  }
  getAdditionalSpecial(leaseId: string) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/additionalspecials`,
      {
        LeaseId: leaseId
      },
      { headers: headers }
    );
  }

  getApprovalType() {
    return this.http.get(
      `${environment.apiEndpoint}/lease/approvaltype`,
      { headers: headers }
    );

  }

  updateApprovalType(body: any) {
    return this.http.put(
      `${environment.apiEndpoint}/lease/approvaltype`,
      body,
      // {
      //   Id: 0,
      //   Approval_Type: approvalType,
      //   Is_Active: 0
      // },
      { headers: headers }
    );
  }

  getLeaseSpecials(leaseObj: any) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/leasespecial`,
      leaseObj,
      { headers: headers }
    );
  }

  getLeaseSpecialUpdated(leaseObje: any) {
    return this.http.put(
      `${environment.apiEndpoint}/lease/leasespecial`,
      leaseObje,
      { headers: headers }
    );
  }
  getlist() {
    return this.http.get(`${environment.apiEndpoint}/user/list`);
  }
  createusers(userData: any) {
    return this.http.post(`${environment.apiEndpoint}/user/create`, userData, { headers: headers });
  }
  deleteUser(userId: any) {
    return this.http.delete(`${environment.apiEndpoint}/user/remove/`+userId);
  }
  getroles() {
    return this.http.get(`${environment.apiEndpoint}/user/roles/list`);
  }
  getportfolio() {
    return this.http.get(`${environment.apiEndpoint}/user/portfolio/list`);
  }
  getusers(id: any) {
    console.log(id)
    return this.http.get(`${environment.apiEndpoint}/user/${id}`);
  }
  getUserCompanyList() {
    return this.http.get(`${environment.apiEndpoint}/user/company/list`);
  }
  updatePassowrd(payload:any){
    return this.http.patch(`${environment.apiEndpoint}/user/password/update`,payload);
  }
}
