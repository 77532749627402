<div class="helpSupprt">
    <div class="pageTitle"> Settings </div>
    <div class="row">
        <div class="col-md-8 ">
            <div class="helpSupprtLeft">
                <div class="helpColmn">
                    <div class="helpText">PERSONAL SETTINGS</div>
                </div>
                <div class="userDetailsContainer">
                    <div class="profileImgBox">
                        <div class="profileImg" *ngIf="auth.user$ | async as user">
                            <img src="{{ user.picture }}" alt="" />
                        </div>
                        <div class="settingsCommonBtn">
                            <button (click)="resetPassword()" >Change Password</button>
                        </div>
                    </div>
                    <div class="userInfo">
                        <div class="helpText">USER INFORMATION</div>
                        <button class="editBtn">Edit</button>
                        <div class="row mt-2" *ngFor="let items of personalinfo">
                            <div class="col-md-6 lh-lg">First Name:<span class="ml-2">{{items.FirstName}}</span></div>
                            <div class="col-md-6 lh-lg">Last Name:<span class="ml-2">{{items.LastName}}</span></div>
                            <div class="col-md-6 lh-lg">Email:<span class="ml-2">{{items.Email}}</span></div>
                            <div class="col-md-6 lh-lg">Phone:<span></span></div>
                            <div class="col-md-6 lh-lg">Company:<span class="ml-2">{{items.Company}}</span></div>
                            <!-- <div class="col-md-6 lh-lg">Title:<span class="ml-2">{{items.Title}}</span></div> -->
                            <div class="col-md-6 lh-lg">
                                <span>Role:<span class="ml-2">{{items.Role}}</span></span>
                            </div>
                            <!-- <div class="col-md-6 lh-lg">
                                <span *ngIf="items.Role == roleType"><strong>Operator:</strong><span
                                        class="ml-2">{{userOperator}}</span></span>
                            </div> -->
                            <div class="col-md-6 lh-lg">
                                <!-- Portfolio -->
                                <div class="justify-content-center mr-1 " style=" ">Porfolio
                                    <select class="form-select text-white weekdays-subheader"
                                        style="padding-right: 2rem" [(ngModel)]="selectedPortfolio"
                                        (change)="onPortfolioChange()">
                                        <option *ngFor="let prop of porfolioName" [ngValue]="prop">{{prop}}</option>
                                    </select>
                                </div>

                            </div>
                            <div class="col-md-6 lh-lg">
                                <!-- Property -->
                                <div class="justify-content-center mr-1 " style=" ">Properties
                                    <select class="form-select text-white weekdays-subheader"
                                        style="padding-right: 2rem">
                                        <option *ngFor="let prop of this.selectedProperties" [ngValue]="prop">{{prop}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="agGridTableContainer">
        <div class="searchTableData">
            <span class="searchInputField">
                <label>Users</label>
                <span class="d-flex position-relative">
                    <i class="bi bi-search"></i>
                    <input type="text" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" />
                </span>
            </span>

            <div class="d-flex align-items-center justify-content-between ml-4 ml-sm-2 text-white">
                <button (click)="onExportClick()" class="nav-link nav-icon btn-list btn btn-success mr-4"><img
                        style="height: 20px" src="assets/img/excel.svg" alt="" /></button>
                <button *ngIf="this.userRole == roleType" type="button" class="btn createNewProp-btn"
                      (click)="onAddEditUser('Add User',null)">
                    Add User
                </button>
            </div>
        </div>
        <ag-grid-angular *ngIf="userRole" style="width: 100%; height:400px" class="ag-theme-alpine agGridTable" [rowData]="rowData"
            [columnDefs]="columnDefs" [cacheQuickFilter]="true" (gridReady)="onGridReady($event)" 
            [gridOptions]="gridOptions" #aggrid (cellClicked)="onCellClicked($event)">
        </ag-grid-angular>
    </div>
</div>
