<header class="header">
  <nav>
    <div class="d-flex">
      <div class="logo"><img src="assets/img/updated-icons/logo.svg" (click)="redirect()" class="icon-hamburger"
          style="cursor: pointer ;height: 36px;" alt="" aria-hidden="true" />
      </div>
      <div class="dataSelection">
        <div class="content-wrapper mr-auto" *ngIf="conventionalType">
          <select class="ms-3 pe-6 form-select student-type " [(ngModel)]="headerDropdownValue"
            (ngModelChange)="onChangeConventional($event)">
            <option value="1">Student Housing</option>
            <option value="2">Conventional</option>

          </select>
        </div>
      </div>

    </div>
    <div class="d-flex justify-content-center align-items-center">
      <div [ngClass]="isActiveNotification?'active-notification':''" *ngIf="isShowOnly">
        <img
          [src]="isActiveNotification?'assets/img/updated-icons/active-notifications.svg':'assets/img/updated-icons/icon-notifications.svg'"
          class="icon-hamburger dropdown-toggle user-image" style="cursor: pointer" alt="" aria-hidden="true"
          role="button" id="dropdownMenuLink1" (click)="notificationState()" data-bs-toggle="dropdown"
          aria-expanded="false" />
        <!-- <div class="dropdown-menu"[ngStyle]="newLeasCount?.length==0?{'display': 'none'}:{}"aria-labelledby="dropdownMenuLink1">
      
          <div class="card-div d-flex px-2 py-2" *ngFor="let lease of newLeasCount">
            <span> <img src="assets/img/updated-icons/propertyname.svg" class="icon-hamburger " style="cursor: pointer"
                alt="" /></span>
            <span class="d-flex flex-column property-name">
              <span class="card-title">{{lease?.Property_Name}}</span>
              <span class="card-sub-title">Special Name</span>
            </span>
            <span class="d-flex flex-column">
              <span class="property-value">{{lease.Leases}}</span>
              <span class="property-value">04</span>
            </span>
          </div>
        </div> -->
      </div>
      <div class="content-wrapper">
        <div class="justify-content-center mr-4 active-class operator" *ngIf="operatorList?.length>0">
          <select class="ml-3 form-select " [(ngModel)]="selectedoperator" (ngModelChange)="onOperatorChange($event)">
            <option *ngFor="let operator of operatorList" [ngValue]="operator">
              {{ operator.Operator_Name }}
            </option>
          </select>
        </div>
        <div class="dropdown user-image-wrapper" *ngIf="auth.user$ | async as user">
          <img src="{{ user.picture }}" alt="" class="dropdown-toggle user-image" role="button" id="dropdownMenuLink"
            data-bs-toggle="dropdown" aria-expanded="false" />
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <div class="d-flex justify-content-center px-3 py-3">
                <img src="{{ user.picture }}" alt="" class="dropdown-toggle user-image" role="button"
                  id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                <div class="profile-info">
                  <span class="dropdown-item ">{{ user.name }}</span>
                  <span class="user-title">User Title / Email</span>
                </div>
              </div>
            </li>
            <li>
              <div class="profile-section"><a routerLink="/personal-detail">My Profile</a> </div>
            </li>
            <li>
              <div class="client-details">
                <span class="">Clients</span>
                <!-- <div class="justify-content-center mr-4 active-class operator" *ngIf="operatorList?.length>0"> -->
                <!-- <select class="ml-3 form-select " [(ngModel)]="selectedoperator"
                    (ngModelChange)="onOperatorChange($event)">
                    <option *ngFor="let operator of operatorList" [ngValue]="operator">
                      {{ operator.Operator_Name }}
                    </option>
                  </select> -->
                <div class="operator-name" *ngFor="let operator of operatorList">{{ operator.Operator_Name }}</div>
                <!-- </div> -->
              </div>
            </li>
            <li>
              <button class="dropdown-item" (click)="logout()">Logout</button>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>