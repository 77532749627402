import { Component, Input, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LeaseService } from '@app/services/lease.service';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss'],
})
export class LeftnavComponent implements OnInit {
  @Input()
  inputFromParent: any;
  list: any = [];
  leaseList = [
    {
      number: '2',
      name: 'Portfolio',
      icon: 'icon icon-portfolio',
      routerLink: '/leasing/portfolio',
      tooltip: 'Portfolio',
    },
    {
      number: '3',
      name: 'Properties',
      icon: 'icon icon-properties',
      routerLink: '/leasing/property',
      tooltip: 'Properties',
    },
    {
      number: '4',
      name: 'Lease',
      icon: 'icon icon-lease',
      routerLink: '/leasing/lease',
      tooltip: 'Lease',
    },
  ];
  conventionalList = [
    {
      number: '2',
      name: 'conventional_lease',
      icon: 'icon icon-lease',
      routerLink: '/leasing/conventionallease',
      tooltip: 'Leases',
    },
    {
      number: '3',
      name: 'conventional_Properties',
      icon: 'icon icon-properties',
      routerLink: '/leasing/conventionalproperty',
      tooltip: 'Properties',
    },
    {
      number: '4',
      name: 'conventional_portfolio',
      icon: 'icon icon-portfolio',
      routerLink: '/leasing/conventionalportfolio',
      tooltip: 'Portfolio',
    },
  ];

  markeNavList = [
    {
      name: 'Markets',
      icon: 'icon icon-marketplace',
      routerLink: '/market-overview/markets',
      tooltip: 'Markets',
    },
    {
      name: 'Compsets',
      icon: 'icon icon-compset',
      routerLink: '/market-overview/compset',
      tooltip: 'Compsets',
    },
    {
      name: 'Properties',
      icon: 'icon icon-properties',
      routerLink: '/market-overview/market-overview-property',
      tooltip: 'Properties',
    },
  ];

  selectedValue: any = '1';
  roleList: any = [];
  constructor(public router: Router, private auth: AuthService, private userService: UserService, private leaseService: LeaseService) { }

  ngOnInit(): void {
    this.list = this.leaseList
    this.auth.user$.subscribe((res: any) => {
      if (res) {
        res.isUserViewOnly = res['HexIndex/roles'].includes('SHViewOnlyUser');
        this.userService.userInfo = res;
        this.roleList = res['HexIndex/roles'];
      }
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('market')) {
          this.selectedValue = '2'
          this.list = this.markeNavList
        } else if (event.url.includes('leasing')) {
          this.selectedValue = '1'
          if (event.url.includes('conventional')) {
            this.list = this.conventionalList;
          }
          else {
            this.list = this.leaseList;
          }
        }
      }
    });
    if (this.router.url.includes('market')) {
      this.selectedValue = '2';
      this.list = this.markeNavList;
    }
  }
}

