<router-outlet *ngIf="router.url === '/' || router.url === '/access-denied'"></router-outlet>

<div *ngIf="router.url !== '/' && router.url !== '/access-denied'">
  <app-header></app-header>
  <app-leftnav></app-leftnav>
  <div class="warpper" [ngClass]="chckabc? 'show' : 'default'">
    <div *ngIf="visible"  class="loader-wrapper1" [ngClass]="chckabc? 'show' : 'default'"> <div class="loader"></div></div> 
    <router-outlet>
    </router-outlet>
  </div>

</div>
