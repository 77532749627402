import { Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaseService } from '@app/services/lease.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { concat, forkJoin } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-setting-modal',
  templateUrl: './setting-modal.component.html',
  styleUrls: ['./setting-modal.component.scss']
})
export class SettingModalComponent implements OnInit, OnChanges {
  userForm: FormGroup;
  role: any[] = [];
  portfolioList: any[] = [];
  clonePortfolioList: any[] = [];
  propertyList: any[] = [];
  title: any;
  isEdit = false;
  userInfo: any;
  closeBtn = false;
  constructor(private fb: FormBuilder, private leasingservice: LeaseService, public bsModalRef: BsModalRef,
  ) {
  }
  ngOnChanges() {

  }
  companyList: any;
  ngOnInit(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      company: ['',],
      title: ['',],
      portfolio: [''],
      property: [''],
      roletype: ['', Validators.required],
    });
    this.getData();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      maxHeight: 110,
      "defaultOpen": false,
      "enableCheckAll": true,
      "itemsShowLimit": 999999,
      "allowSearchFilter": true,
      "limitSelection": null,
      dropDownPosition: 'top',
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'portfolio_id',
      textField: 'portfolio_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      maxHeight: 110,
      "defaultOpen": false,
      "enableCheckAll": false,
      "allowSearchFilter": true
    };

  }
  isChangesData=false;
  isLoader=true;
  getData() {
    let portfolioList = this.leasingservice.getportfolio();
    let roleList = this.leasingservice.getroles();
    let companyList = this.leasingservice.getUserCompanyList();
    this.isLoader=true;
    forkJoin([portfolioList, roleList, companyList]).subscribe((res: any) => {
      this.role = res[1];
      this.clonePortfolioList = res[0];
      this.companyList = res[2];
      this.isLoader=this.userInfo?.UserId?true:false;
      if (this.userInfo?.UserId) {
        this.getUserByUserId(this.userInfo.UserId);
        this.isChangesData=true;
      }
    })
  }
  onSelectOperator(id: any) {
    this.userForm.controls.portfolio.setValue('');
    this.userForm.controls.roletype.setValue('');
    this.userForm.controls.property.setValue('');
    this.disabled1 = true;
    this.userForm.controls.portfolio.clearValidators();
    this.userForm.controls.portfolio.updateValueAndValidity();
    this.userForm.controls.portfolio.disable();
    id ? this.userForm.controls.roletype.enable() : this.userForm.controls.roletype.disable();
    this.userForm.controls.property.disable();
    const portfolioData: any = this.clonePortfolioList;
    this.portfolioList = this.filterOperater(portfolioData)

  }
  filterOperater(portfolio: any) {
    const filteredItems = portfolio.map((item: any) => {
      return {
        ...item,
        Properties: item.Properties.filter((prop: any) => prop.Operator_Id == this.userForm.controls.company.value) // Filter items based on value
      };
    });
    return filteredItems.filter((innerArray: any) => { return innerArray.Properties.length > 0 });
  }
  isShowProperty = false;
  onRoleChange(value: any) {
    value=this.userForm.controls.roletype.value;
    this.propertyList=[];
    const portfolioData: any = this.clonePortfolioList;
    this.portfolioList = this.filterOperater(portfolioData)
    this.disabled1 = false;
    this.userForm.controls.portfolio.addValidators([Validators.required]);
    this.userForm.controls.portfolio.updateValueAndValidity();
    this.userForm.controls.portfolio.setValue('')
    this.userForm.controls.property.setValue('');
    this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 99999 });
    this.handleLimitSelection(false);
    if (value.role_id == 4 || value.role_id == 5) {
      this.disabled = false;
      this.userForm.controls.property.enable();
      this.userForm.controls.portfolio.enable();
      this.userForm.controls.property.addValidators([Validators.required]);
      this.userForm.controls.property.updateValueAndValidity();
      this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 1 });
      this.isShowProperty = false;

      this.handleLimitSelection(true);
      this.toogleShowAll(false);
      // this.toogleShowFilter(true);
    } else if (value.role_id == 2 || value.role_id == 1) {
      this.disabled = true;
      this.isShowProperty = true;
      this.userForm.controls.portfolio.enable();
      this.handleLimitSelection(false);
      this.toogleShowAll(false);
      // this.toogleShowFilter(false);
      if (this.portfolioList.length > 0 ) {
        const selectedPortfolio: any = this.portfolioList?.filter((item: any) => { return item.portfolio_id == -1 });
        this.userForm.controls.portfolio.setValue(selectedPortfolio);
        this.onSelectPortfolio();
      }

    } else {
      this.disabled = true;
      const portfolioData: any = this.clonePortfolioList;
      const filterData = this.filterOperater(portfolioData);
      this.portfolioList = filterData.filter((item: any) => item.portfolio_id !== -1);
      this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 99999 });
      this.handleLimitSelection(false);
      this.toogleShowAll(true);
      // this.toogleShowFilter(true);
      this.userForm.controls.portfolio.enable();
      this.userForm.controls.property.clearValidators();
      this.userForm.controls.property.updateValueAndValidity();
    }
  }
  selectedAllProperties = [];
  filterProperties(selectedPortfolio: any) {
    let selectedProp: any = [];
    selectedPortfolio.forEach((element: any) => {
      selectedProp = selectedProp.concat(element.Properties);
    });
    this.selectedAllProperties = this.onselectedProperties(selectedProp);
    const prop = Array.from(
      new Map(selectedProp.map((item: any) => [item.Id, item])).values()
    );
    return prop;
  }

  onselectedProperties(properties: any) {
    let prop = properties?.map((item: any) => {
      return {
        "Portfolio": item.Portfolio_Id, "Property": item.Id
      };
    });
    return prop;
  }
  onSelectPortfolio() {
    this.userForm.controls.property.setValue('');
    const filteredArray = this.portfolioList.filter(item1 =>
      this.userForm.controls.portfolio.value.find((item2: any) => item2.portfolio_id === item1.portfolio_id)
    );
    this.propertyList = this.filterProperties(filteredArray)
    if (this.userForm.controls.roletype.value.role_id == 4 || this.userForm.controls.roletype.value.role_id == 5) {
      this.userForm.controls.property.setValue('');
    } else {
      this.userForm.controls.property.setValue(this.propertyList);
    }
  }
  onSubmit() {
    if (this.userForm.invalid) {
      return;
    } else {
      this.isLoader=true;
      if (this.userInfo?.UserId) {
        this.updateUser(this.userForm.controls)
      } else {
        this.addNewUser(this.userForm.controls);
      }
    }
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  dropdownSettings: any
  disabled: any = false;
  dropdownSettings1: any
  disabled1: any = false;
  addNewUser(userInfo: any) {
    let selectedCompany = this.companyList.filter((item: any) => item.Operator_Id == userInfo.company.value);
    if (userInfo.roletype.value.role_id == 4 || userInfo.roletype.value.role_id == 5) {
      let selectedProp: any = userInfo.property.value.map((item: any) => { return item.Id });
      this.selectedAllProperties = this.selectedAllProperties.filter((item: any) => selectedProp.includes(item.Property))
    }
    let user = {
      "OperatorId": userInfo.company.value,
      "FirstName": userInfo.name.value,
      "LastName": userInfo.lastname.value,
      "Company": selectedCompany[0].Operator_Name,
      "Title": userInfo.title.value,
      "Property": this.selectedAllProperties,
      "Email": userInfo.email.value,
      "Phone": userInfo.phone.value,
      "UserRole": userInfo.roletype.value.role_id,
    }
    this.leasingservice.createusers(user).subscribe((res: any) => {
      if (res) {
        this.isLoader=false;
        if (res.status_code == 409) {
          Swal.fire({
            title: "User Already Exists",
          });
        }
        else {
          Swal.fire({
            title: "User Created Successfully",
          });
          this.closeBtn = true
          this.closeModal();
        }
      }
    })
  }

  updateUser(userInfo: any) {
    let selectedCompany = this.companyList.filter((item: any) => item.Operator_Id == userInfo.company.value)
    if (userInfo.roletype.value.role_id == 4 || userInfo.roletype.value.role_id == 5) {
      let selectedProp: any = userInfo.property.value.map((item: any) => { return item.Id });
      this.selectedAllProperties = this.selectedAllProperties.filter((item: any) => selectedProp.includes(item.Property))
    }
    let user = {
      "OperatorId": userInfo.company.value,
      "FirstName": userInfo.name.value,
      "LastName": userInfo.lastname.value,
      "Company": selectedCompany[0].Operator_Name,
      "Title": userInfo.title.value,
      "Property": this.selectedAllProperties,
      "Email": userInfo.email.value,
      "Phone": userInfo.phone.value,
      "UserRole": userInfo.roletype.value.role_id,
    }
    this.leasingservice.updateUsers(user, this.userInfo.UserId).subscribe((res: any) => {
      if (res) {
        this.isLoader=false;
        Swal.fire({
          title: "User Updated Successfully",
        });
        this.closeBtn = true
        this.closeModal();
      }
    })
  }
  toogleShowAll(value: boolean) {
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      enableCheckAll: value
    });
  }
  toogleShowFilter(value: boolean) {
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      allowSearchFilter: value
    });
  }
  handleLimitSelection(value: boolean) {
    if (value) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 99999 });

    }
  }
  setUserFormData(userData: any) {
    let selectedRole = this.role.filter(item => item.role_name == userData.Role[0]);
    const filterArray= userData.Portfolios.map((item:any)=>Number(item.Id));
    const selectedPortfolio = this.clonePortfolioList.filter(item =>filterArray.includes(item.portfolio_id));
    const selectedProp = this.filterProperties(selectedPortfolio);
    this.onGetRoleChange(selectedRole[0])
    this.userForm.patchValue({
      name: userData.FirstName,
      lastname: userData.LastName,
      email: userData.Email,
      phone: '',
      company: userData.Portfolios[0].Properties[0].OperatorId,
      title: userData.Title,
      roletype: selectedRole[0],
    })
    this.userForm.controls.portfolio.setValue(selectedPortfolio)
    this.propertyList = selectedProp;
    if(selectedRole[0].role_id==4 || selectedRole[0].role_id==5){
     const filterArrayProp=userData.Portfolios[0].Properties.map((item:any)=>Number(item.Id));
      const selectedProp = this.propertyList.filter(item =>filterArrayProp.includes(item.Id));
      this.propertyList=this.propertyList;
    this.userForm.controls.property.setValue(selectedProp);

  }else{
    this.userForm.controls.property.setValue(selectedProp);
  
  }
    this.userForm.controls.email.disable()
    const portfolioData: any = this.clonePortfolioList;
   const filterData = this.filterOperater(portfolioData);
   if(selectedRole[0].role_id == 3 ){
    this.portfolioList = filterData.filter((item: any) => item.portfolio_id !== -1);
  }else{
    this.portfolioList = filterData;
  }

  }
  getUserByUserId(userId: any) {
    this.leasingservice.getusers(userId).subscribe((res: any) => {
      if (res) {
        this.isLoader=false;
        this.setUserFormData(res[0]);
      }
    })
  }
  onGetRoleChange(value: any) {
    this.disabled1 = false;
    this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 99999 });
    this.handleLimitSelection(false);
    if (value.role_id == 4 || value.role_id == 5) {
      this.disabled = false;
      this.dropdownSettings1 = Object.assign({}, this.dropdownSettings1, { limitSelection: 1 });
      this.handleLimitSelection(true);
      this.toogleShowAll(false);
    } else if (value.role_id == 2 || value.role_id == 1) {
      this.disabled = true;
      // this.isShowProperty = true;
      this.handleLimitSelection(false);
      this.toogleShowAll(false);
    } else {
      this.disabled = true;
      this.handleLimitSelection(false);
      this.toogleShowAll(true);
    }
  }
}
