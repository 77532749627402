import { Component, inject, ViewChild } from "@angular/core";
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import { LeaseService } from "@app/services/lease.service";
import { SharedService } from "@app/services/shared.service";
import { Subscription } from "rxjs";
import { AuthService } from "@auth0/auth0-angular";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { SettingModalComponent } from "./setting-modal/setting-modal.component";
import Swal from "sweetalert2";
import { UserService } from "@app/services/user.service";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ExcelService } from "@app/services/excel.service";

@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent {
	subscription: Subscription = new Subscription();
	showEditPopup: boolean = false;
	myFiles: any[] = [];
	rowData: any = [];
	params: any;
	personalinfo: any;
	porfolioName: any;
	filterProperty: any;
	selectedPortfolio: any;
	selectedProperties: any[] = [];
	userOperator: any;
	roleType: any;
	userRole: any;
	private sharedService = inject(SharedService);
	userId: string | null;
	isAdmin: boolean = false;
	public auth = inject(AuthService);
	constructor(private userService: UserService,
		private modalService: BsModalService, private excelService: ExcelService,
		private bsModalRef: BsModalRef,) { }
	@ViewChild("aggrid") child!: any;

	private leasingservice = inject(LeaseService);


	ngOnInit() {
		this.userService.userInfo.subscribe((res: any) => {
			this.userId = res?.userid;
			if (this.userId) {
				this.leasingservice.getusers(this.userId).subscribe((res: any) => {
					if (res) {
						this.personalinfo = res;
						this.porfolioName = this.personalinfo[0].Portfolios.map((portfolio: any) => portfolio.Name);
						this.selectedPortfolio = this.porfolioName[0];
						this.filterProperty = this.personalinfo[0].Portfolios.filter((item: { Name: string; }) => item.Name === this.selectedPortfolio)
						this.selectedProperties = this.filterProperty[0].Properties.map((property: any) => property.Name);
						this.userOperator = this.filterProperty[0].Properties[0].OperatorName;
						this.roleType = "SH Super Admin";
						this.userRole = this.personalinfo[0].Role;
					}
				})
			}
		})
		this.getUserList();
	}


	getFileDetails(e: any) {
		for (var i = 0; i < e.target.files.length; i++) {
			this.myFiles.push(e.target.files[i]);
		}
	}

	public defaultColDef: ColDef = {
		sortable: true,
		filter: true,
	};

	private gridApi!: GridApi;
	dataChange: any = '';

	columnDefs: ColDef[] = [
		{ field: 'UserId', headerName: "ID", hide: true, width: 100, minWidth: 80, maxWidth: 100, headerClass: 'agGridHeadStyle text-wrap', cellClass: "text-center" },
		{ field: 'Company', headerName: "Company", hide: true, width: 100, minWidth: 80, maxWidth: 100, headerClass: 'agGridHeadStyle text-wrap', cellClass: "text-center" },
		{ field: 'FirstName', headerName: "First Name", width: 150, minWidth: 90, maxWidth: 150, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'LastName', headerName: "Last Name", width: 150, minWidth: 90, maxWidth: 150, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ headerName: "Portfolio", wrapText: true, valueGetter: this.getPortfolios, width: 450, minWidth: 70, maxWidth: 450, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ headerName: "Property", wrapText: true, valueGetter: this.getProperty, width: 390, minWidth: 70, maxWidth: 400, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'Title', headerName: "Title", width: 200, minWidth: 70, maxWidth: 300, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'Email', headerName: "Email Address", width: 200, minWidth: 90, maxWidth: 300, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'Role', headerName: "User Role", width: 150, minWidth: 80, maxWidth: 150, headerClass: 'agGridHeadStyle text-wrap', cellClass: "text-center" },
		{
			headerName: 'Actions', width: 110, minWidth: 80,hide:false, maxWidth: 110, headerClass: 'agGridHeadStyle text-wrap', cellClass: "text-center",
			cellRenderer: (params: any) => { 
				return params.data.Editable==1?`<span class="edit"> <i class="icon icon-edit" data-action="update" aria-hidden="false"></i> </span><span class="edit"> <i class="icon icon-delete" data-action="delete" aria-hidden="false"></i></span>`:''; 
			},
		}
	];

	getPortfolios(params: any): string {
		return params?.data?.Portfolios?.map((portfolio: any) => portfolio?.Name)?.join(", ");
	}
	getProperty(params: any): string {
		if (params.data.Role == "User" || params.data.Role == "View Only User") {
			const properties = params?.data?.Portfolios?.flatMap((portfolio: any) => portfolio.Properties.map((property: any) => property.Name));
			const prop = Array.from(
				new Map(properties?.map((item: any) => [item, item])).values()
			);
			return prop.join(', ');
		}
		else {
			return "";
		}
	}


	editUser(data: any) {
		this.showEditPopup = true;
	}
	onFilterTextBoxChanged() {
		this.gridApi.setQuickFilter(
			(document.getElementById("filter-text-box") as HTMLInputElement).value,
		);
	}


	onGridReady
		(params: any) {
		params.api.
			addEventListener('cellClicked', (event: any) => {
				if (event.column.colId === 'Actions' && event.event.target.classList.contains(
					'edit-btn'
				)) {
					this.editUser(event.data);
				}
			});
		this.gridApi = params.api;
		this.params = params;
		this.gridColumnApi = params.columnApi;
		this.updateColumnVisibility();
	}
	gridColumnApi: any;
	updateColumnVisibility() {
		if (this.userRole?.includes('SH Super Admin')) {
			this.columnDefs = this.columnDefs.map(col => {
				if (col.field === "Company") {
					return { ...col, hide: false };
				}
				return col;
			});
		}
		if (this.userRole?.includes('User')) {
			this.columnDefs = this.columnDefs.map(col => {
				if (col.headerName === "Actions") {
					return { ...col, hide: true };
				}
				return col;
			});
		}
	}

	gridOptions: GridOptions = {
		columnDefs: this.columnDefs,
		rowData: this.rowData
	};
	allData:any=[];
	onExportClick() {
		if(this.allData?.length==0){
		this.gridApi.forEachNode((node: any) => {
			node.data['Portfolio'] = this.getPortfolios(node);
			node.data['Property'] = this.getProperty(node);
			this.allData.push(node.data);
		});}
	let filterData:any=	[]
	this.allData.forEach((item:any)=>{
			if(this.userRole[0]!=='SH Super Admin'){
			delete item.Company;
		}
			filterData.push(item);
		})
		console.log(filterData)
		const columnOrder: any = ['Company', 'FirstName', 'LastName', 'Portfolio', 'Property', 'Title', 'Email', 'Role'].filter(item=>this.userRole[0]!=='SH Super Admin'?item!=='Company':item);
		const orderedData = filterData.map((row: any) => {
			return columnOrder.map((key: any) => row[key] );
		});
		const columnHeader: any = ['Company', 'First Name', 'Last Name', 'Portfolio', 'Property', 'Title', 'Email Address', 'User Role'].filter(item=>this.userRole[0]!=='SH Super Admin'?item!=='Company':item);
		if(this.userRole[0]!=='SH Super Admin'){
			const colsWidth: any = [
				{ wpx: 70 },
				{ wpx: 70 },
				{ wpx: 200 },
				{ wpx: 100 },
				{ wpx: 200 },
				{ wpx: 200 },
				{ wpx: 150 },
			];
			this.excelService.exportToExcel(orderedData, columnHeader, colsWidth, 'Users List');
		}else{
			const colsWidth: any = [
				{ wpx: 150 },
				{ wpx: 70 },
				{ wpx: 70 },
				{ wpx: 200 },
				{ wpx: 100 },
				{ wpx: 200 },
				{ wpx: 200 },
				{ wpx: 150 },
			]
			this.excelService.exportToExcel(orderedData, columnHeader, colsWidth, 'Users List');

		}
	}

	onPortfolioChange() {
		if (this.selectedPortfolio) {
			let selectedData = this.personalinfo[0].Portfolios.filter((item: { Name: string; }) => item.Name === this.selectedPortfolio);
			this.selectedProperties = selectedData[0].Properties.map((property: any) => property.Name);
		} else {
			this.selectedProperties = [];
		}
	}
	onAddEditUser(userTest: string, data: any) {
		const initialState: ModalOptions = {
			id: userTest,
			backdrop: true,
			initialState: {
				title: userTest,
				userInfo: data
			},
			class: 'modal-lg',
		};
		this.bsModalRef = this.modalService.show(
			SettingModalComponent,
			initialState
		);
		this.bsModalRef.onHide?.subscribe(() => {
			if (this.bsModalRef.content?.closeBtn) {
				this.getUserList();
			}
		});
		this.sharedService.modalRef = this.bsModalRef;
	}
	resetPassword() {
		const initialState: ModalOptions = {
			initialState: {
				title: 'Change Password',
				userDetails: this.personalinfo
			},
			class: 'modal-lg',
		};
		this.bsModalRef = this.modalService.show(
			ChangePasswordComponent,
			initialState
		);
		this.sharedService.modalRef = this.bsModalRef;
	}
	cloneData=[];
	getUserList() {
		this.leasingservice.getlist().subscribe((res: any) => {
			this.rowData = res;
			this.cloneData=res;
		});
	}
	onCellClicked(params: any) {
		if (
			params.event.target.dataset.action
		) {
			let action = params.event.target.dataset.action;

			if (action === "update") {
				this.onAddEditUser('Edit User', params.data)
			}

			if (action === "delete") {
				Swal.fire({
					title: '',
					text: 'Are you sure , you want to delete this user?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No'
				}).then((result) => {
					if (result.isConfirmed) {
						this.leasingservice.deleteUser(params.data.UserId).subscribe((res: any) => {

							if (res?.status_code == 204) {
								this.getUserList();
								Swal.fire({ title: 'User Removed Successfully' });

							}
						})
					} else if (result.dismiss === Swal.DismissReason.cancel) {

					}
				})

			}


		}
	}

}
