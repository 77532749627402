
<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
  </div>
  <div class="modal-body portfolio-modalbody">
    <form [formGroup]="changePassword"  (ngSubmit)="onSubmit()"  novalidate >
        <div class="row mt-2">
            <div class="mb-3 col-md-12">
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">First Name</label>
                        <input type="text" id="name" formControlName="name" class="form-control" [disabled]="true"
                            />
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Last Name</label>
                        <input type="text" id="name" formControlName="lastname" class="form-control" [disabled]="true"
                            />
                    </div>
                </div>
            </div>
            <div class="mb-3 col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" id="email" formControlName="email" class="form-control"
                            placeholder="Enter your email" />
                        
                    </div>
                    <div class="col-md-6">
                        <label for="phone" class="form-label">Phone Number</label>
                        <input type="tel" id="phone" formControlName="phone" class="form-control" [disabled]="true"/>
                        
                    </div>
                </div>
            </div>
            <div class="mb-3 col-md-12">
                <div class="row">
                    <div class="col-md-4">
                      <label for="currentPassword" >Old password</label>
                      <div class="" style="display: flex;">
                      <input [type]="password" id="currentPassword" formControlName="currentPassword" class="form-control" 
                      [ngClass]="{ 'is-invalid': changePassword.get('currentPassword')?.invalid && changePassword.get('currentPassword')?.touched  }"required/> 
                            <div class="password-show-btn" (click)="onClick(1)">
                              <i [ngClass]="password == 'password' ? 'icon-hide' : 'icon-show'" class="icon" aria-hidden="true"></i>
                              </div>
                          </div>
                          <div *ngIf="currentPassword.invalid && (currentPassword.dirty || currentPassword.touched) "class="form-text text-danger">
                          <div *ngIf="currentPassword.errors?.['required']"> This field is required</div>
                          </div>                    
                  </div>
                    <div class="mb-3 col-md-4">
                        <label for="newPassword">New password </label>
                        <div class="" style="display: flex;">
                        <input
                          id="newPassword"
                          [type]="npassword"
                          class="form-control"
                          formControlName="newPassword"
                          [ngClass]="{ 'is-invalid': changePassword.get('newPassword')?.invalid && changePassword.get('newPassword')?.touched ,
                          'is-valid': !changePassword.get('newPassword')?.invalid }"
                          #pw
                        />
                        <div class="password-show-btn"(click)="onClick(2)">
                          <i [ngClass]="npassword == 'password' ? 'icon-hide' : 'icon-show'" class="icon" aria-hidden="true"></i>

                          </div>
                      </div>
                        <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)"class="form-text text-danger">
                          <div *ngIf="newPassword.errors?.['required'] && !changePassword.get('newPassword')?.value">
                            This field is required
                          </div>
                          <div class="text-red-400" *ngIf="changePassword.get('newPassword')?.value && !newPassword.errors?.['confirmedValidator']">
                            <div  [ngClass]="{'text-success': changePassword.get('newPassword')?.value?.match('^(?=.*[A-Z])')}">* At least one uppercase letter.</div>
                            <div [ngClass]="{'text-success': changePassword.get('newPassword')?.value?.match('(?=.*[a-z])')}"> * At least one lowercase letter.</div>
                            <div  [ngClass]="{'text-success': changePassword.get('newPassword')?.value?.match('(.*[0-9].*)')}"> * At least one number.</div>
                            <div [ngClass]="{'text-success': changePassword.get('newPassword')?.value?.match('(?=.*[!@#$%^&*])')}">* At least one special character.</div>
                            <div [ngClass]="{'text-success': changePassword.get('newPassword')?.value?.match('.{8,}')}">* Minimum 8 characters.</div>
                            <div [ngClass]="{'text-success': !newPassword.errors?.['cannotContainSpace']}">* No spaces.</div>
  
                         </div>
                          <div *ngIf="newPassword.errors?.['confirmedValidator']">
                            Old & New password should not be same!
                          </div>
                        </div>
                        
                      </div>
                      <div class="mb-3 col-md-4">
                        <label for="confirmPassword">Confirm password </label>
                        <div class="" style="display: flex;">
                        <input
                          id="confirmPassword"
                          class="form-control"
                          [type]="cpassword"
                          [ngClass]="confirmPasswordClass"
                          formControlName="confirmPassword"
                          [ngClass]="{ 'is-invalid': changePassword.get('confirmPassword')?.invalid && changePassword.get('confirmPassword')?.touched ,
                          'is-valid': !changePassword.get('confirmPassword')?.invalid}"
                          #cpw
                        />
                        <div class="password-show-btn" (click)="onClick(3)">
                        <i [ngClass]="cpassword == 'password' ? 'icon-hide' : 'icon-show'" class="icon" aria-hidden="true"></i>
                        </div>
                    </div>
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) "class="form-text text-danger">
                          <div *ngIf="confirmPassword.errors?.['required']">
                            This field is required
                          </div>
                          <div *ngIf="confirmPassword.errors?.['confirmedValidator']">
                            password do not match
                          </div>
                        </div>
                      </div>
                      
                </div>
            </div>
           
        </div>
        <div class="modal-footer" >
            <button
              type="button" class="btn btn-primary" type="submit" [disabled]="!changePassword.valid">
              {{ isEdit ? "Update" : "Submit" }}
            </button>
          </div>
    </form>
  </div>
  
  