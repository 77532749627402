
<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
  </div>
  <div class="modal-body portfolio-modalbody">
    <form [formGroup]="changePassword"  (ngSubmit)="onSubmit()" >
        <div class="row mt-2">
            <div class="mb-3 col-md-12">
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">First Name</label>
                        <input type="text" id="name" formControlName="name" class="form-control" [disabled]="true"
                            />
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Last Name</label>
                        <input type="text" id="name" formControlName="lastname" class="form-control" [disabled]="true"
                            />
                    </div>
                </div>
            </div>
            <div class="mb-3 col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" id="email" formControlName="email" class="form-control"
                            placeholder="Enter your email" />
                        
                    </div>
                    <div class="col-md-6">
                        <label for="phone" class="form-label">Phone Number</label>
                        <input type="tel" id="phone" formControlName="phone" class="form-control" [disabled]="true"/>
                        
                    </div>
                </div>
            </div>
            <div class="mb-3 col-md-12">
                <div class="row">
                    <div class="col-md-4">
                      <label for="currentPassword" class="form-label">Old password</label>
                      <input type="password" id="currentPassword" formControlName="currentPassword" class="form-control"
                          [ngClass]="{ 'is-invalid': changePassword.get('currentPassword')?.invalid && changePassword.get('currentPassword')?.touched }"
                            required/>
                      <div *ngIf="changePassword.get('currentPassword')?.invalid && changePassword.get('currentPassword')?.touched"
                          class="invalid-feedback">
                          Field is required
                      </div>
                  </div>
                    <div class="mb-3 col-md-4">
                        <label for="newPassword">New password: </label>
                        <input
                          id="newPassword"
                          type="password"
                          class="form-control"
                          formControlName="newPassword"
                          [ngClass]="{ 'is-invalid': changePassword.get('newPassword')?.invalid && changePassword.get('newPassword')?.touched }"
                          #pw
                        />
                        <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)"class="form-text text-danger">
                          <div *ngIf="newPassword.errors?.['required']">
                            Field is required
                          </div>
                          <div *ngIf="newPassword.errors?.['pattern']">
                            *Password must contain at least one number, one uppercase and a lowercase letter<br>
                            *Password must be of at least 8 characters<br>
                            *Password cannot contain whitespace<br>
                          </div>
                          <div *ngIf="newPassword.errors?.['confirmedValidator']">
                            Old password & New password should not be same.
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 col-md-4">
                        <label for="confirmPassword">Confirm password </label>
                        <input
                          id="confirmPassword"
                          type="password"
                          class="form-control"
                          [ngClass]="confirmPasswordClass"
                          formControlName="confirmPassword"
                          [ngClass]="{ 'is-invalid': changePassword.get('confirmPassword')?.invalid && changePassword.get('confirmPassword')?.touched }"
                          #cpw
                        />
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) "class="form-text text-danger">
                          <div *ngIf="confirmPassword.errors?.['required']">
                            Field is required
                          </div>
                          <div *ngIf="confirmPassword.errors?.['pattern']">
                            *Password must contain at least one number, one uppercase and a lowercase letter<br>
                            *Password must be of at least 8 characters<br>
                            *Password cannot contain whitespace<br>
                          </div>
                          <div *ngIf="confirmPassword.errors?.['confirmedValidator']">
                            Passwords did not match
                          </div>
                        </div>
                      </div>
                </div>
            </div>
           
        </div>
        <div class="modal-footer" >
            <button
              type="button" class="btn btn-primary" type="submit" [disabled]="!changePassword.valid">
              {{ isEdit ? "Update" : "Submit" }}
            </button>
          </div>
    </form>
  </div>
  
  