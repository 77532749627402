<div class="helpSupprt">
    <div class="pageTitle">
        Settings
    </div>
    <div class="agGridTableContainer" *ngIf="!userRole?.includes('User') && !userRole?.includes('View Only User')">
        <div class="searchTableData">
            <span class="searchInputField">
                <label>User Management</label>
                <span class="search-div d-flex position-relative">
                    <input type="text" id="filter-text-box" placeholder="Search" (input)="onFilterTextBoxChanged()" />
                </span>
            </span>

            <div class="d-flex align-items-center justify-content-between ml-4 ml-sm-2 text-white">
                <button (click)="onExportClick()" class="nav-link nav-icon btn-list btn btn-success mr-4"><img
                        style="height: 20px" src="assets/img/excel.svg" alt="" /></button>
                <button type="button" *ngIf="!userRole?.includes('User')" class="btn createNewProp-btn"
                    (click)="onAddEditUser('Add User',null)">
                    <i aria-hidden="false" class="icon icon-add"></i>
                    <span class="px-2">Add User</span>
                </button>
            </div>
        </div>

        <ag-grid-angular *ngIf="userRole" style="width: 100%; height:calc(100vh - 180px)" class="ag-theme-alpine"
            [rowData]="rowData" [columnDefs]="columnDefs" [cacheQuickFilter]="true" (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions" #aggrid (cellClicked)="onCellClicked($event)">
        </ag-grid-angular>
    </div>
</div>