import { EventEmitter, Injectable } from "@angular/core";
import { JwtServiceService } from "@app/services/jwt-service.service";
import { LeaseTerm1, Portfolio, Property } from "@app/utils/types";
import { AuthService } from "@auth0/auth0-angular";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { PropertyService } from "./property.service";

@Injectable({
	providedIn: "root",
})
export class SharedService {
	leaseTerm = "";
	userProperty: Property[] = [];
	userPortfolio: Portfolio[] = [];
	userLeaseTerm: LeaseTerm1[] = [];
	dataLoaded: boolean = false;
	portfolioData: any = "";
	isCreatePortfolio: boolean = false;
	isEditPortfolio: boolean = false;
	isverifyLease: boolean = false;
	portfolioMatrixSubscription!: Subscription;
	portfoliolist: any = [];
	rowData: any = [];
	portfolioCount = new Subject<number>();
	xyz = new Subject();
	WeekArray: any = [];
	isPropertyVisible: boolean = false;
	isOperatorVisible: boolean = true;
	marketId = new Subject();
	compsetId = new Subject();
	weekData = new Subject();
	leaseModalData = [];
	onDataChange = new BehaviorSubject({
		property: "",
		portfolio: "",
		leaseTerm: "",
		operatorId: "",
		operatorName: "",
		isPortfolioSelected: false,
		weekEndDate: "",
		weekStartDate: "",
		selectedMarketValue: "",
		selectedCompsetValue: "",
		marketId: "",
		userId: "",
		marketWeekEndDate: "",
		marketWeekStartDate: "",
	});

	onOperatorchange = new BehaviorSubject(null);


	marketDropdownData: any = {
		marketData: [],
		weekData: [],
		compsetData: [],
		market_data: [],
	};
	marketOverviewDropdownState = new BehaviorSubject<{
		seleteType: "market" | "compset";
		marketId: number | null;
		compsetId: number | null;
		weekStartDate: string | null;
		WeekEndDate: string | null;
		currentWeekEndDate: string | null;
		leaseType: "executed" | "signed";
		weekNumber: number | null;
		compsetCreated: string | null;
		selectedType: string | null;
		operaterID: "";
		marketOrleasing: "1" | "2";

	}>({
		seleteType: "market",
		marketId: null,
		compsetId: null,
		weekStartDate: null,
		WeekEndDate: null,
		currentWeekEndDate: null,
		leaseType: "executed",
		weekNumber: null,
		compsetCreated: null,
		selectedType: null,
		operaterID: "",
		marketOrleasing: "2",
	});

	updateSpecialState = new BehaviorSubject<{
		specialName: string;
		specialValue: number | null;
		specialFrequency: number | null;
	}>({
		specialName: "",
		specialValue: null,
		specialFrequency: null,
	});

	marketOperatorID: string = "";

	getMarketOverviewDropdownState$() {
		return this.marketOverviewDropdownState.asObservable();
	}

	getMarketOverviewDropdownStateValue() {
		return this.marketOverviewDropdownState.getValue();
	}

	updateMarketOverviewDropdownState(newValues: any) {
		this.marketOverviewDropdownState.next({
			...this.marketOverviewDropdownState.value,
			...newValues,
		});
	}

	getUpdateSpecialStateValue() {
		return this.updateSpecialState.getValue();
	}

	updateSpecialStateValue(newValues: any) {
		this.updateSpecialState.next({
			...this.updateSpecialState.value,
			...newValues,
		});
	}

	selectedProperty: any;
	isMarketProperty: boolean = true;
	modalRef: any;
	specialPropertyRef: any;
	isCompsetCreated = new BehaviorSubject<{
		isCreated: boolean;
	}>({ isCreated: false });

	getOnDataChange() {
		return this.onDataChange.getValue();
	}

	updateOnDataChange(newValues: any) {
		this.onDataChange.next({ ...this.onDataChange.value, ...newValues });
	}

	setMarketId(value: any) {
		this.marketId.next(value);
	}

	getMarketId() {
		return this.marketId.asObservable();
	}

	setCompsetId(value: any) {
		this.compsetId.next(value);
	}

	getCompsetId() {
		return this.compsetId.asObservable();
	}
	setWeekData(value: any) {
		this.weekData.next(value);
	}

	getWeekData() {
		return this.weekData.asObservable();
	}
	setName(newoperator: any): void {
		this.onOperatorchange.next(newoperator);
	}
	getName() {
		return this.onOperatorchange.asObservable();
	}
	constructor(
		private auth: AuthService,
		private jwtService: JwtServiceService,
		private propertyService: PropertyService,
	) { }

	toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	operatorId: any;
	onToggleChange(toggleValue: boolean) {
		this.toggleChange.emit(toggleValue);
	}

	verifyPortfoliopermission() {

		// this.isCreatePortfolio = true;
		const token = this.auth
			.getAccessTokenSilently()
			.subscribe((result: any) => {
				let decodeToken: any = this.jwtService.DecodeToken(result);
				let subjectData: any = {};
				subjectData["userId"] = decodeToken.userId;
				this.updateOnDataChange(subjectData);
				let permissions = decodeToken.permissions;
				let permissionsUp = permissions.join(", ");
				let env: string = "";
				if (permissionsUp.includes("create : portfolio")) {
					this.isCreatePortfolio = true;
				}
			});

		return this.isCreatePortfolio;
	}
	verifyPortfoliopermissionToEdit() {
		const token = this.auth
			.getAccessTokenSilently()
			.subscribe((result: any) => {
				let decodeToken: any = this.jwtService.DecodeToken(result);
				let permissions = decodeToken.permissions;
				let env: string = "";
				if (permissions.includes("edit : portfolio")) {
					this.isEditPortfolio = true;
				}
			});

		return this.isCreatePortfolio;
	}
	verifyLeasePermissionTovalidate() {
		const token = this.auth
			.getAccessTokenSilently()
			.subscribe((result: any) => {
				let decodeToken: any = this.jwtService.DecodeToken(result);
				let permissions = decodeToken.permissions;
				let env: string = "";
				if (permissions.includes("read : lease")) {
					this.isverifyLease = false;
				}
			});

		return this.isverifyLease;
	}
	getPortfolioList() {
		this.portfolioMatrixSubscription?.unsubscribe();
		this.portfolioMatrixSubscription = this.propertyService
			.getPortfolioMatrix({
				Operator_Id: this.getLeasingSubheaderStateValue().operaterID,
				Lease_Term: this.getLeasingSubheaderStateValue().leaseTerm,
				Weekending_Date: this.getLeasingSubheaderStateValue().WeekEndDate,
				Portfolio_Id: null,
			})
			.subscribe((data: any) => {
				this.rowData = data.sort((a: any, b: any) =>
					a.Name.localeCompare(b.Name),
				);
				this.portfoliolist = this.rowData?.map(({ Name }: { Name: string }) =>
					Name.toLowerCase(),
				);
			});
		return this.portfoliolist;
	}

	leasingOperatorID: string = "";
	leasingPropertyList: any[] = [];
	leasingPortfolioList: any[] = [];
	leaseWeekData: any;

	leasingSubheaderState = new BehaviorSubject<{
		operaterID: string | null;
		seleteType: "property" | "portfolio";
		propertyId: number | null;
		portfolioId: number | null;
		leaseTerm: string | null;
		weekStartDate: string | null;
		WeekEndDate: string | null;
		isExecuted: 0 | 1;
		ischanged: boolean;
	}>({
		operaterID: null,
		seleteType: "property",
		propertyId: null,
		portfolioId: null,
		leaseTerm: null,
		weekStartDate: null,
		WeekEndDate: null,
		isExecuted: 1,
		ischanged: false,
	});

	getLeasingSubheaderState$() {
		return this.leasingSubheaderState.asObservable();
	}

	getLeasingSubheaderStateValue() {
		return this.leasingSubheaderState.getValue();
	}

	updateLeasingSubheaderState(newValues: any) {
		this.leasingSubheaderState.next({
			...this.leasingSubheaderState.value,
			...newValues,
		});
	}

	getCompsetCreatedState$() {
		return this.isCompsetCreated.asObservable();
	}

	getCompsetCreatedStateValue() {
		return this.isCompsetCreated.getValue();
	}

	updateCompsetCreatedState(newValues: any) {
		this.isCompsetCreated.next({
			...this.isCompsetCreated.value,
			...newValues,
		});
	}
	getcurrentMarketid() {
		return this.marketOverviewDropdownState.getValue().marketId;
	}
	isCurrentWeek() {
		return (this.getMarketOverviewDropdownStateValue().WeekEndDate == this.getMarketOverviewDropdownStateValue().currentWeekEndDate)
	}
	isConventional: any = new BehaviorSubject(this.conventionalData);
	set conventionalData(value) {
		if (value)
			this.isConventional.next(value)
	}
	get conventionalData() {
		return this.isConventional;
	}
	isHeaderCheckBox: any = new BehaviorSubject(this.conventionalData);
	set headerCheckBox(value) {
		if (value)
			this.isConventional.next(value)
	}
	get headerCheckBox() {
		return this.isConventional;
	}
	isConventionaltoggle: any = new BehaviorSubject(this.conventionalDataToggle);
	set conventionalDataToggle(value) {
		if (value)
			this.isConventionaltoggle.next(value)
	}
	get conventionalDataToggle() {
		return this.isConventionaltoggle;
	}
	/*for market conventional screen */
	isConventionalMarket: any = new BehaviorSubject(this.conventionalDataMarket);
	set conventionalDataMarket(value) {
		if (value)
			this.isConventionalMarket.next(value)
	}
	get conventionalDataMarket() {
		return this.isConventionalMarket;
	}
	isConventionaltoggleMarket: any = new BehaviorSubject(this.conventionalDataToggleMarket);
	set conventionalDataToggleMarket(value) {
		if (value)
			this.isConventionaltoggleMarket.next(value)
	}
	get conventionalDataToggleMarket() {
		return this.isConventionaltoggleMarket;
	}

}
