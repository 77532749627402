import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }
  user:any=new BehaviorSubject(this.userInfo);
  set userInfo(value){
    this.user.next(value)
  }
  get userInfo(){
   return this.user;
  }
}
