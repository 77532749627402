import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
// import { AuthService } from '@auth0/auth0-angular';
// import { CarouselConfig } from 'ngx-bootstrap/carousel';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { ReportService } from './services/report.service';
// import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public router = inject(Router)

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }

}
