<main class="main">
    <section id="hero">
        <header id="header" class="d-flex align-items-center">
            <div class="container d-flex align-items-center">
                <h1 class="logo me-auto"></h1>
               <nav id="navbar" class="navbar">
                    <ul>
                        <li><a href="index.html" class="active">About Us</a></li>
                        <li><a href="services.html">Contact Us</a></li>
                        <li><a href="portfolio.html">Need help ?</a></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                </nav>
            </div>
        </header>

        <div style="height: 600px">
            <carousel [isAnimated]="true">
                <slide *ngFor="let slide of slides; let index = index">
                    <div class="container">
                        <div class="row sh-row-margin">
                            <div class="col-md-5 align-items-center">
                                <div class="carousel-sh">
                                    <img [src]="slide.logo" alt="image slide" style="
                        width: 176px;
                        height: 140px;
                        margin-bottom: 32px;
                        margin-left: -34px;
                      " />
                                    <h2>{{ slide.heading }}</h2>
                                    <p>{{ slide.text }}</p>
                                    <button class="btn-get-started animate__animated animate__fadeInUp scrollto"
                                        (click)="login()">
                                        {{ slide.button }}
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="carousel-sh2">
                                    <img [src]="slide.image" alt="image slide" class="sh-slider-img img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
        <footer id="footer">
            <div class="container">
                <div class="copyright">A product of Bailey Technology Group</div>
            </div>
        </footer>
    </section>
</main>