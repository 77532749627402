import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }
  user:any=new BehaviorSubject(this.userInfo);
  set userInfo(value){
    this.user.next(value)
  }
  get userInfo(){
   return this.user;
  }
  // ================//
  operator:any=new BehaviorSubject(this.selectedOperator);
  set selectedOperator(value){
    this.operator.next(value)
  }
  get selectedOperator(){
   return this.operator;
  }
  // ================//
  // headerCheck:any=new BehaviorSubject(this.conventionalType);
  // set conventionalType(value){
  //   this.headerCheck.next(value)
  // }
  // get conventionalType(){
  //  return this.operator;
  // }
  // ================//
   // ================//
   conventionalType=new Subject();
   
   // ================//

  
}
