import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeasingApiService {

  private http = inject(HttpClient)

  getPropertyList() {
    return this.http.get<any[]>(
      `${environment.apiEndpoint}/properties/userentitledproperties`
    );
  }
  getConventionalPropertyList() {
    return this.http.get<any[]>(
      `${environment.apiEndpoint}/properties/list`
    );
  }

  getLeaseTermList() {
    return this.http.get(`${environment.apiEndpoint}/lease/userleaseterm`);
  }

  getPortfolioList() {
    return this.http.get(`${environment.apiEndpoint}/portfolio`);
  }
  deletePortfolioListById(id:number) {
    const options = {
      body: {
        Portfolio_Id: id
      }
    }
    return this.http.delete(`${environment.apiEndpoint}/portfolio`, options);
  }

  getUserloginHistory(login_status:string) {
    return this.http.get(`${environment.apiEndpoint}/user/loginhistory/${encodeURIComponent(login_status)}`);
  }

  getPortfolioMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/portfolio/matrics`,
      payloadObject,
    );
  }

  getPropertyMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/metrics`,
      payloadObject,
    );
  }

  getEntitledLease(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/userentitledleases`,
      payloadObject,
    );
  }

  getPropertyRollUpMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/rollupmatrics`,
      payloadObject,
    );
  }

  getWeeklyPreleaseTrend(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/weeklypreleasetrends`,
      payloadObject,
    );
  }

  getRentGrowthMetrics(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/rentgrowthmatrix`,
      payloadObject,
    );
  }

  getRevenueDetail(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/revenuedetails`,
      payloadObject,
    );
  }

  getRentGrowth(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/rentgrowth`,
      payloadObject,
    );
  }

  getPropertyUnitTypeInfo(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/unittypeinfo`,
      payloadObject,
    );
  }

  getPortfolioUnitTypeInfo(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/portfolio/unittypeinfo`,
      payloadObject,
    );
  }

  setUserLoginHistory(data:any){
    return this.http.post(
      `${environment.apiEndpoint}/user/loginhistory`,
      data,
    );
  }

  // getipAddress(){
  //   return this.http.get("http://api.ipify.org/?format=json");
  // }

}
