import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LeaseTerm } from '@app/utils/types';
const headers = new HttpHeaders()
  // .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Accept', 'application/pdf')
  .set('responseType', 'text');

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  _propertyList: BehaviorSubject<any> = new BehaviorSubject([]);
  _selectedProperty: BehaviorSubject<string> = new BehaviorSubject<string>('');
  weekDateChange = new EventEmitter<any>();
  isPortfolioSelected: boolean = false;
  dashboardChange = new Subject<any>();
  private dataSubject = new Subject<any>();
  readonly properties$ = this._propertyList.asObservable();

  constructor(private http: HttpClient) { }

  getSelectedProperty(): Observable<string> {
    return this._selectedProperty.asObservable();
  }

  setSelectedProperty(value: string): void {
    this._selectedProperty.next(value);
  }

  getPropertyList() {
    return this.http.get<any[]>(
      `${environment.apiEndpoint}/properties/userentitledproperties`
    );
  }

  emitOperatorid(data: any) {
    this.dataSubject.next(data);
  }

  observeOperatorid() {
    return this.dataSubject.asObservable();
  }

  

  getLeaseInfo() {
    return this.http.get(`${environment.apiEndpoint}/lease`);
  }

  getPropertyMatrix(payloadObject: PropertyMatrix) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/metrics`,
      payloadObject,
      { headers: headers }
    );
  }

  // getPropertyRollUpMatrix(payloadObject: RollupMatrics) {
  //   return this.http.post(
  //     `${environment.apiEndpoint}/properties/rollupmatrics`,
  //     payloadObject,
  //     { headers: headers }
  //   );
  // }

  getRentGrowthMetrics(payloadObject: RentGrowthMetrics) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/rentgrowthmatrix`,
      payloadObject,
      { headers: headers }
    );
  }

  getPortfolioMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/portfolio/matrics`,
      payloadObject,
      { headers: headers }
    );
  }

  getDailyStats(payloadObject: DailyStats) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/dailystats`,
      payloadObject,
      { headers: headers }
    );
  }

  getWeeklyPreleaseTrend(payloadObject: WeeklyPreleaseTrend) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/weeklypreleasetrends`,
      payloadObject,
      { headers: headers }
    );
  }

  getEntitledLease(payloadObject: EntitledLease) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/userentitledleases`,
      payloadObject,
      { headers: headers }
    );
  }

  getRevenueDetail(payloadObject: RevenueDetail) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/revenuedetails`,
      payloadObject,
      { headers: headers }
    );
  }

  getRentGrowth(payloadObject: RentGrowth) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/rentgrowth`,
      payloadObject,
      { headers: headers }
    );
  }

  getLeaseTermList() {
    return this.http.get(`${environment.apiEndpoint}/lease/userleaseterm`, {
      headers: headers,
    });
  }

  getPortfolioList() {
    return this.http.get(`${environment.apiEndpoint}/portfolio`);
  }

  getAllPropertyId() {
    return this.http.get(
      `${environment.apiEndpoint}/portfolio/listall`,

      { headers: headers }
    );
  }

  addPortfolio(addBody: any) {
    return this.http.post(`${environment.apiEndpoint}/portfolio`, addBody, {
      headers: headers,
    });
  }

  updatePortfolio(updateBody: any) {
    return this.http.put(`${environment.apiEndpoint}/portfolio`, updateBody, {
      headers: headers,
    });
  }


 

  getSpecials(propertyData: proprtyDetails) {
    return this.http.post(`${environment.apiEndpoint}/properties/specials`, propertyData, {
      headers: headers,
    });
  }
}

type RollupMatrics = {
  PropertyId: string[];
  LeaseTerm: string;
  WeekEndingDate: string;
  OperatorId: string;
};

type RentGrowthMetrics = {
  Operator_Id: string;
  Portfolio_Id?: string;
  Property_Id?: string;
  Lease_Term: string;
  Week_Ending_Date: string;
};

type PortfolioMatrix = {
  Operator_Id: string;
  Portfolio_Id?: any;
  Lease_Term: string;
  Weekending_Date: string;
};

type WeeklyPreleaseTrend = {
  Operator_Id: string;
  Portfolio_Id?: any;
  Property_Id?: any;
  Lease_Term: string;
};

type DailyStats = {
  OperatorId: string;
  DateFrom: string;
  DateTo: string;
  PortfolioId?: string;
  PropertyId?: string;
};

type PropertyMatrix = {
  OperatorId: string;
  PropertyId: any[];
  LeaseTerm: string;
  WeekEndingDate: string;
};

type EntitledLease = {
  Operator_Id: string;
  Portfolio_Id?: string;
  Property_Id?: string;
  Lease_Term: string;
  WeekEndingDate: string;
};

type RevenueDetail = {
  Operator_Id: string;
  Property_Id: string;
  Lease_Term: string;
  Week_Ending_Detail: string;
};

type RentGrowth = {
  Operator_Id: string;
  Property_Id: string;
  Lease_Term: string;
  Week_Ending_Date: string;
};

type proprtyDetails = {
  "PropertyId": number,
  "PortfolioId": number,
  "WeekEndingDate": string
}
