import { Component, OnInit, inject } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Event, NavigationEnd, Router } from "@angular/router";
import { SharedService } from "@app/services/shared.service";
import { LeasingApiService } from "@app/services/leasing-api.service";
import { UserService } from "@app/services/user.service";
import { forkJoin } from "rxjs";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	selectedValue = "1";
	isConventionalSelected: boolean = true;
	isConventionalMarketSelected = true;
	private router = inject(Router);
	public auth = inject(AuthService);
	public userService = inject(UserService);
	conventionalType: boolean=false;
	conventionalMarket: any;
	operatorList:any=[];
	selectedoperator:any;
	propertyList=[];
	weekList=[];
	portfolioList=[];
	conventionalPropertyList=[];
	constructor(
		private sharedService: SharedService,
		private leasingApiService:LeasingApiService
	) {
		if(this.router.url.includes('leasing/conventionaldashboard')){
			this.isConventionalSelected=false
		}
		
    this.getListData();
	}
	getListData(){
		let obsverables = [
			this.leasingApiService.getLeaseTermList(),
			this.leasingApiService.getPortfolioList(),
			this.leasingApiService.getPropertyList(),
			this.leasingApiService.getConventionalPropertyList()
		];
		forkJoin(obsverables).subscribe((res: any) => {
			if(res){
			this.weekList = res[0];
			this.portfolioList = res[1];
			this.conventionalPropertyList=res[3]
				this.propertyList = res[2]?.sort((a: any, b: any) =>
					a.PropertyName.localeCompare(b.PropertyName),
				);
					this.operatorList = res[2].filter(
					(property: any, index: any, self: any) =>
						index ===
						self.findIndex(
							(p: any) => p.Operator_Id === property.Operator_Id,
						),
				)
				.sort((a: any, b: any) =>
					a.Operator_Name.localeCompare(b.Operator_Name),
				);
				this.selectedoperator=this.operatorList[0];
				this.sharedService.marketOperatorID = this.operatorList[0].Operator_Id;

				this.sharedService.leasingPropertyList = this.propertyList;
				if(this.selectedoperator.Operator_Id == "18371453-902e-11ee-b225-000d3a53b49c"){
					if(this.router.url.includes('market-overview')){
						this.conventionalType=false;
					}else{
						this.conventionalType=true;
					}
				}
				let object={
					operatorList: this.operatorList,
					propertyList:this.propertyList,
					selectedoperator:this.selectedoperator,
					weekList:this.weekList,
					portfolioList:this.portfolioList,
					conventionalPropertyList:this.conventionalPropertyList
				}
				this.userService.conventionalType.next(this.isConventionalSelected)
				this.userService.selectedOperator=object;
			}
		});
	}
	onOperatorChange(event:any){

		if(event.Operator_Id=="18371453-902e-11ee-b225-000d3a53b49c"){
			if (this.router.url.includes('conventionaldashboard') && this.isConventionalSelected==false) {
				this.router.navigate(['/leasing']);
			}
			this.conventionalType=true;
		}else{
			this.conventionalType=false;

		}
		let object={
			operatorList: this.operatorList,
			propertyList:this.propertyList,
			selectedoperator:event,
			weekList:this.weekList,
			portfolioList:this.portfolioList
		}
		this.userService.selectedOperator=object;
		this.sharedService.marketOperatorID = event.Operator_Id;
	}
	
	onChangeConventional(event: any) {
			this.isConventionalSelected=event;
			if(this.isConventionalSelected){
				this.router.navigate(['/leasing']);
		}
			else if (this.isConventionalSelected == false) {
				this.router.navigate(['/leasing/conventionaldashboard']);
			}
			this.userService.conventionalType.next(event)
	}
	onDropdownChange(event: any) {
		this.sharedService.conventionalDataMarket.next(event);
	}
	onChange(event: any) {
		let stateData = this.sharedService.getMarketOverviewDropdownStateValue();
		stateData.marketOrleasing = event;
		this.sharedService.updateMarketOverviewDropdownState(stateData);
		this.userService.conventionalType.next(this.isConventionalSelected)
		if (event === "1") {
			if(this.selectedoperator.Operator_Id == "18371453-902e-11ee-b225-000d3a53b49c"){
				if(this.router.url.includes('market-overview')!==true){
					this.conventionalType=true;
				}else{
					this.conventionalType=false;
				}
			}
			if (this.isConventionalSelected) {
				this.router.navigate(["leasing"]);
			}
			else {
				this.router.navigate(["leasing/conventionaldashboard"]);
			}

		} else if (event === "2") {
			this.conventionalType = false;
			this.conventionalMarket = true
			this.router.navigate(["market-overview"]);

		}
	}

	ngOnInit(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
			  if (event.url.includes('market')) {
				this.conventionalType=false;
			  } else if (event.url.includes('leasing') && this.selectedoperator.Operator_Id == "18371453-902e-11ee-b225-000d3a53b49c") {
				this.conventionalType=true;
			  }
			}
		  });
		if (this.router.url.includes("market")) {
			this.selectedValue = "2";
			this.conventionalType = false;
		}
		if (this.router.url.includes("conventional")) {
			this.isConventionalSelected=false;
		}
	}

	logout() {
		this.auth.logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}

	redirect() {
		if (this.selectedValue === "1") {
			this.router.navigate(["leasing"]);
		} else {
			this.router.navigate(["market-overview"]);
		}
	}
}
