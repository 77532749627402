<header class="header">
  <nav>
    <div class="d-flex">
      <div class="logo"><img src="assets/img/monogramdashboard.svg" (click)="redirect()" class="icon-hamburger"
          style="cursor: pointer" alt="" aria-hidden="true" />
      </div>
      <div class="dataSelection">
        <div class="content-wrapper mr-auto">
          <select class="ms-3 p-1 form-select bg-danger text-white" [(ngModel)]="selectedValue"
            (ngModelChange)="onChange($event)">
            <option value="1" selected>Leasing</option>
            <option value="2">Markets</option>
            <option value="3" disabled>Marketing (Coming soon)</option>
          </select>
        </div>
        <div class="switch-btn" *ngIf="conventionalType">
          <label class="pe-2 mb-0 text-white" for="forSwitchSelection1">{{ isConventionalSelected ?
            "Student":"Conventional"
            }}</label>
          <label class="switch">
            <input type="checkbox" id="forSwitchSelection1" [(ngModel)]="isConventionalSelected"
              (ngModelChange)="onChangeConventional($event)">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
     
    </div>
    <div class="content-wrapper">
      <div class="justify-content-center mr-4 active-class operator" *ngIf="operatorList?.length>0">
        <select class="ml-3 form-select text-white weekdays-subheader" [(ngModel)]="selectedoperator" (ngModelChange)="onOperatorChange($event)">
          <option *ngFor="let operator of operatorList" [ngValue]="operator">
            {{ operator.Operator_Name }}
          </option>
        </select>
      </div>
      <div class="dropdown user-image-wrapper" *ngIf="auth.user$ | async as user">
        <img src="{{ user.picture }}" alt="" class="dropdown-toggle user-image" role="button" id="dropdownMenuLink"
          data-bs-toggle="dropdown" aria-expanded="false" />
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li>
            <span class="dropdown-item disabled">{{ user.name }}</span>
          </li>
          <li>
            <button class="dropdown-item" (click)="logout()">Logout</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- <app-lease-subheader *ngIf="propertyList.length>0" [selectedoperator]="selectedoperator" [propertiesList]="propertyList" [conventionalType]="conventionalType" [selectedValue]="selectedValue"></app-lease-subheader> -->
